import { createSelector } from 'reselect';
import { settingsSelectors } from 'store/settings';

const getStatus = (state) => state.cache.usageStatus.status.detail;
const getUsageStatusData = (state) => state.cache.usageStatus.data.detail.item;

const getStatesForUsageStatus = createSelector(
  [getUsageStatusData, settingsSelectors.getLoadingByStatus([getStatus])],
  (usageStatusData, isLoading) => ({ usageStatusData, isLoading })
);

const usageStatusSelectors = {
  getStatesForUsageStatus,
};

export default usageStatusSelectors;
