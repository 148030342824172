import { has } from 'lodash';
import { useSelector, shallowEqual } from 'react-redux';
import usageStatusSelectors from 'store/usage-status/selectors';
import { USAGE_STATUS_FIELDS } from 'domain/usage-status/consts';

const { OPTIONS } = USAGE_STATUS_FIELDS;
const CROSS_PAID = 2;
const NO_CROSS_PAID = 0;

const useCheckCrossDevicePaidContract = () => {
  const { usageStatusData, isLoading } = useSelector(
    usageStatusSelectors.getStatesForUsageStatus,
    shallowEqual
  );

  const { [OPTIONS]: options = {} } = usageStatusData;
  const paidContract = has(options, 'CROSS_PAID-1')
    ? CROSS_PAID
    : NO_CROSS_PAID;

  return {
    paidContract,
  };
};

export default useCheckCrossDevicePaidContract;
