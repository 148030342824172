import {
  REPORT_TYPE,
  CHANNEL_TYPE,
  CHANNEL_TYPE_ID,
  CHANNEL_TYPE_LABEL,
  CHANNEL_SUMMARY_KEY,
} from 'domain/consts';
import {
  CHANNEL_CLICK_PERMISSIONS,
  CHANNEL_DIRECT_PERMISSIONS,
  CHANNEL_REFERAL_PERMISSIONS,
  CHANNEL_SEARCH_PERMISSIONS,
  CHANNEL_SITECONTENT_PERMISSIONS,
  CHANNEL_VIEW_PERMISSIONS,
  LOG_CONTRACT_DISPLAY_CHANNEL,
  LOG_CONTRACT_DISPLAY_CHANNEL_SITE_CONTENT,
} from 'domain/permissions/contractGroups';
import {
  CATEGORY_ANALYZE,
  DETAILS_ANALYSIS,
  COMPARE_PERIOD,
  LANDING_PAGE_ANALYZE,
  CV_ATTRIBUTE,
  CV_FLOW,
  LOG_PAGE_ANALYZE,
  LOG_PERIOD_ANALYZE,
  INFLOW_ANALYSIS,
  DATA_EXPORT,
} from 'services/routes/constants';

const FilterConfig = {
  displayChannels: [
    // { key: 'view', value: '広告ビュー' },
    { key: CHANNEL_TYPE_ID.CLICK, value: '広告クリック' },
    { key: CHANNEL_TYPE_ID.SEARCH, value: '自然検索' },
    {
      key: CHANNEL_TYPE_ID.REFERRAL,
      value: '外部リンク',
      permissionSet: LOG_CONTRACT_DISPLAY_CHANNEL,
    },
    {
      key: CHANNEL_TYPE_ID.DIRECT,
      value: 'ダイレクト',
      permissionSet: LOG_CONTRACT_DISPLAY_CHANNEL,
    },
    {
      key: CHANNEL_TYPE_ID.SITE_CONTENT,
      value: CHANNEL_TYPE.site_content,
      allowScreen: [LANDING_PAGE_ANALYZE, CV_ATTRIBUTE, CV_FLOW],
      permissionSet: LOG_CONTRACT_DISPLAY_CHANNEL_SITE_CONTENT,
    },
  ],
  displayChannelsRange: [
    // { key: 'view', value: '広告ビュー' },
    { key: CHANNEL_TYPE_ID.CLICK, value: '広告クリック' },
    { key: CHANNEL_TYPE_ID.SEARCH, value: '自然検索' },
    {
      key: CHANNEL_TYPE_ID.REFERRAL,
      value: '外部リンク',
      permissionSet: LOG_CONTRACT_DISPLAY_CHANNEL,
    },
    {
      key: CHANNEL_TYPE_ID.DIRECT,
      value: 'ダイレクト',
      permissionSet: LOG_CONTRACT_DISPLAY_CHANNEL,
    },
    {
      key: CHANNEL_TYPE_ID.SITE_CONTENT,
      value: CHANNEL_TYPE.site_content,
      allowScreen: [LANDING_PAGE_ANALYZE, CV_ATTRIBUTE, CV_FLOW],
      permissionSet: LOG_CONTRACT_DISPLAY_CHANNEL_SITE_CONTENT,
    },
  ],
  devices: [
    { key: '0', value: 'PC' },
    { key: '2', value: 'スマートフォン' },
    { key: '3', value: 'タブレット' },
    { key: '1', value: 'モバイル' },
    {
      key: '999',
      value: '不明',
      denyScreens: [LOG_PAGE_ANALYZE, LOG_PERIOD_ANALYZE],
    },
  ],
  summaryChannels: [
    {
      ids: ['10', '20', '30', '40', '50'],
      key: CHANNEL_SUMMARY_KEY.CLICK_SEARCH_REFERRAL_DIRECT_SITECONTENT,
      value: 'クリック+自然検索+ダイレクト+外部リンク+サイト内',
      allowScreen: [LANDING_PAGE_ANALYZE, CV_ATTRIBUTE, CV_FLOW],
      permissionSet: LOG_CONTRACT_DISPLAY_CHANNEL_SITE_CONTENT,
    },
    {
      ids: ['10', '20', '30', '40'],
      key: CHANNEL_SUMMARY_KEY.CLICK_SEARCH_REFERRAL_DIRECT,
      value: 'クリック+自然検索+ダイレクト+外部リンク',
      permissionSet: LOG_CONTRACT_DISPLAY_CHANNEL,
    },
    {
      ids: ['10', '20'],
      key: CHANNEL_SUMMARY_KEY.CLICK_SEARCH,
      value: 'クリック+自然検索',
    },
  ],
  summaryAllChannels: [
    {
      key: CHANNEL_TYPE_ID.VIEW,
      value: CHANNEL_TYPE_LABEL[CHANNEL_TYPE_ID.VIEW],
      permissionSet: {
        [REPORT_TYPE.CHANNEL]: CHANNEL_VIEW_PERMISSIONS,
        [REPORT_TYPE.CVATTR]: CHANNEL_VIEW_PERMISSIONS,
      },
    },
    {
      key: CHANNEL_TYPE_ID.CLICK,
      value: CHANNEL_TYPE_LABEL[CHANNEL_TYPE_ID.CLICK],
      permissionSet: {
        [REPORT_TYPE.CHANNEL]: CHANNEL_CLICK_PERMISSIONS,
        [REPORT_TYPE.CVATTR]: CHANNEL_CLICK_PERMISSIONS,
      },
    },
    {
      key: CHANNEL_TYPE_ID.SEARCH,
      value: CHANNEL_TYPE_LABEL[CHANNEL_TYPE_ID.SEARCH],
      permissionSet: {
        [REPORT_TYPE.CHANNEL]: CHANNEL_SEARCH_PERMISSIONS,
        [REPORT_TYPE.CVATTR]: CHANNEL_SEARCH_PERMISSIONS,
      },
    },
    {
      key: CHANNEL_TYPE_ID.REFERRAL,
      value: CHANNEL_TYPE_LABEL[CHANNEL_TYPE_ID.REFERRAL],
      permissionSet: {
        [REPORT_TYPE.CHANNEL]: CHANNEL_REFERAL_PERMISSIONS,
        [REPORT_TYPE.CVATTR]: CHANNEL_REFERAL_PERMISSIONS,
      },
    },
    {
      key: CHANNEL_TYPE_ID.DIRECT,
      value: CHANNEL_TYPE_LABEL[CHANNEL_TYPE_ID.DIRECT],
      permissionSet: {
        [REPORT_TYPE.CHANNEL]: CHANNEL_DIRECT_PERMISSIONS,
        [REPORT_TYPE.CVATTR]: CHANNEL_DIRECT_PERMISSIONS,
      },
    },
    {
      key: CHANNEL_TYPE_ID.SITE_CONTENT,
      value: CHANNEL_TYPE_LABEL[CHANNEL_TYPE_ID.SITE_CONTENT],
      permissionSet: {
        [REPORT_TYPE.CHANNEL]: CHANNEL_SITECONTENT_PERMISSIONS,
        [REPORT_TYPE.CVATTR]: CHANNEL_SITECONTENT_PERMISSIONS,
      },
    },
  ],
  adTypes: [
    { key: 'ad', value: 'AD広告' },
    { key: 'lpo', value: 'LPO広告' },
    { key: 'listing', value: 'リスティング連携広告' },
    { key: 'all', value: 'すべて' },
  ],
  measurementTypes: [
    { key: '0', value: '全て' },
    { key: '1', value: 'リダイレクト計測' },
    { key: '2', value: 'ダイレクト計測' },
  ],
  cross_device: [
    {
      key: '0',
      value: '分析結果を反映する',
    },
  ],
  // key = '1' will be apply search_console filter
  searchConsole: [
    {
      key: '1',
      value: '反映する',
    },
  ],
};

const HiddenFilterConfig = {
  cross_device: [
    CATEGORY_ANALYZE,
    DETAILS_ANALYSIS,
    COMPARE_PERIOD,
    CV_ATTRIBUTE,
    CV_FLOW,
    INFLOW_ANALYSIS,
  ],
  channel_access_type: [CATEGORY_ANALYZE, DETAILS_ANALYSIS, COMPARE_PERIOD],
};

export { FilterConfig, HiddenFilterConfig };
