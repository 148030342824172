import { CONTENT_TYPE, TABLE_ROWS_PER_PAGE } from 'domain/consts';
import { convertArray2Object } from 'domain/utils';
import { convertApiDetailAdManagement } from 'domain/responseUtils';
import { call, put, select, takeLatest, all } from 'redux-saga/effects';
import isString from 'lodash/isString';
import adManagementServiceApi from 'services/api/AdManagementApi';
import { communicationStatus, HttpStatus } from 'services/utils';
import adGroup1Service from 'services/api/settings/AdGroup1Service';
import adGroup2Service from 'services/api/settings/AdGroup2Service';
import mediaService from 'services/api/settings/MediaService';
import adManagementSettingsSelectors from 'store/ad-management-settings/selectors';
import adManagementSelectors from 'store/ad-management/selectors';
import { loggerActions } from 'store/logger';
import loggerConstants from 'store/logger/constant';
import { masterDataActions } from 'store/master-data';
import sharedSagas from 'store/sharedSagas';
import commonActions from 'store/common/actions';
import handleError from 'services/error/handleScopeError';
import {
  mediaAccountService,
  mediaSideCampaignService,
  mediaSideGroupService,
} from 'services/api/settings/MediaSideService';
import { isArray } from 'lodash';
import adManagementActions from './actions';
import types from './types';

const { LOADING, UPDATING, SUCCEEDED, FAILED } = communicationStatus;

const OFFSET = undefined; // set undefined to set default value in search,
const LIMIT = undefined; // set undefined to set default value in func search,

function* handleUploadCsv(action) {
  try {
    yield put(adManagementActions.setStatusCsvUpload(LOADING));
    const { uploadUrl, filename } = yield call(
      adManagementServiceApi.getUploadUrl,
      CONTENT_TYPE.CSV
    );

    const { file } = action.payload;
    yield call(
      adManagementServiceApi.uploadFile,
      file,
      uploadUrl,
      CONTENT_TYPE.CSV
    );

    const mode = yield select(adManagementSelectors.getTab);
    const response = yield call(
      adManagementServiceApi.validateCsv,
      filename,
      mode
    );
    const {
      need_confirm: needConfirm,
      change_url: changeUrl,
      empty_measurement_type: emptyMeasurementType,
    } = response.data.data;

    const data = {
      needConfirm,
      changeUrl,
      emptyMeasurementType,
    };

    yield put(adManagementActions.csvValidationSucceed(data));
    yield put(adManagementActions.setCsvFilename(filename));

    if (needConfirm) {
      yield put(adManagementActions.setStatusCsvUpload(SUCCEEDED));
    } else {
      yield put(adManagementActions.submitCsv());
    }
  } catch (e) {
    const { error: response, scope } = handleError(e?.response || {}, [
      HttpStatus.BAD_REQUEST,
    ]);
    const errors = response?.data?.errors || [];

    yield put(adManagementActions.csvValidationErrors(errors, scope));
    yield put(adManagementActions.setStatusCsvUpload(FAILED));
  }
}

function* handleUploadCsvDelete(action) {
  try {
    yield put(adManagementActions.setStatusCsvUpload(LOADING));

    const { uploadUrl, filename } = yield call(
      adManagementServiceApi.getUploadUrl,
      CONTENT_TYPE.CSV
    );

    const { file } = action.payload;
    yield call(
      adManagementServiceApi.uploadFile,
      file,
      uploadUrl,
      CONTENT_TYPE.CSV
    );

    const mode = yield select(adManagementSelectors.getTab);
    const response = yield call(
      adManagementServiceApi.validateCsvDelete,
      filename,
      mode
    );
    const data = {
      needConfirm: true,
      deleteAds: response.data.data,
    };

    yield put(adManagementActions.setCsvFilename(filename));
    yield put(adManagementActions.csvValidationSucceed(data));
    yield put(adManagementActions.setStatusCsvUpload(SUCCEEDED));
  } catch (e) {
    const { error: response, scope } = handleError(e?.response || {}, [
      HttpStatus.BAD_REQUEST,
    ]);
    const errors = response?.data?.errors || [];

    yield put(adManagementActions.csvValidationErrors(errors, scope));
    yield put(adManagementActions.setStatusCsvUpload(FAILED));
  }
}

function* handleUploadBanner(file, contentType) {
  const { uploadUrl, filename } = yield call(
    adManagementServiceApi.getUploadUrl,
    contentType
  );

  yield call(adManagementServiceApi.uploadFile, file, uploadUrl, contentType);

  // TODO should we update a success status for image upload?
  return filename;
}

function* handleSubmitCsv() {
  try {
    const mode = yield select(adManagementSelectors.getTab);
    const filename = yield select(adManagementSelectors.getUploadFileName);

    yield put(adManagementActions.setStatusCsvUpload(LOADING));
    yield call(adManagementServiceApi.submitCsv, filename, mode);
    yield put(adManagementActions.setStatusCsvUpload(SUCCEEDED));
    yield put(adManagementActions.getList());
  } catch (e) {
    const scopeErrorStatus = [HttpStatus.BAD_REQUEST];
    if (
      e?.response?.status === HttpStatus.SERVER_ERROR &&
      e?.response?.data?.details &&
      isArray(e.response.data.details) &&
      e.response.data.details.length > 0 &&
      // upload manager lock wait timeout
      e.response.data.details[0].errorCode === 'LOCK_WAIT_TIMEOUT'
    ) {
      scopeErrorStatus.push(HttpStatus.SERVER_ERROR);
      yield put(
        loggerActions.logConfirmModal({
          title: 'CSV一括登録に失敗しました。',
          content:
            'しばらく時間をおいてからCSVファイルの再アップロードをお願いいたします',
        })
      );
    }
    const { error: response, scope } = handleError(
      e?.response || {},
      scopeErrorStatus
    );
    const errors = response?.data?.errors || [];

    yield put(adManagementActions.csvValidationErrors(errors, scope));
    yield put(adManagementActions.setStatusCsvUpload(FAILED));
  }
}

function* handleSubmitCsvDelete() {
  try {
    const mode = yield select(adManagementSelectors.getTab);
    const filename = yield select(adManagementSelectors.getUploadFileName);

    yield put(adManagementActions.setStatusCsvUpload(LOADING));
    yield call(adManagementServiceApi.submitCsvDelete, filename, mode);
    yield put(adManagementActions.setStatusCsvUpload(SUCCEEDED));
    yield put(adManagementActions.getList());
  } catch (e) {
    const { error: response, scope } = handleError(e?.response || {}, [
      HttpStatus.BAD_REQUEST,
    ]);
    const errors = response?.data?.errors || [];

    yield put(adManagementActions.csvValidationErrors(errors, scope));
    yield put(adManagementActions.setStatusCsvUpload(FAILED));
  }
}

function* handleCreate(action) {
  try {
    yield put(adManagementActions.setStatusDetail(LOADING));
    const { data } = action.payload;
    const mode = yield select(adManagementSelectors.getTab);
    const bannerFile = data.banner;
    if (bannerFile && !isString(bannerFile)) {
      const fileType = bannerFile.type;
      const bannerFileName = yield handleUploadBanner(bannerFile, fileType);
      data.banner = bannerFileName;
    }

    const createdData = yield call(adManagementServiceApi.createAd, data, mode);

    yield put(
      adManagementActions.setDetail(convertApiDetailAdManagement(createdData))
    );

    yield put(adManagementActions.setStatusDetail(SUCCEEDED));
  } catch (e) {
    const { error: response, scope } = handleError(e?.response || {}, [
      HttpStatus.BAD_REQUEST,
    ]);
    const errors = response?.data?.errors || [];

    yield put(adManagementActions.setErrorDetail(errors, scope));
    yield put(adManagementActions.setStatusDetail(FAILED));
  }
}

function* handleUpdate(action) {
  try {
    yield put(adManagementActions.setStatusDetail(LOADING));

    const { id, data } = action.payload;
    const bannerFile = data.banner;
    if (bannerFile && !isString(bannerFile)) {
      const fileType = bannerFile.type;
      const bannerFileName = yield handleUploadBanner(bannerFile, fileType);
      data.banner = bannerFileName;
    }
    const updatedData = yield call(adManagementServiceApi.updateAd, id, data);

    yield put(
      adManagementActions.setDetail(convertApiDetailAdManagement(updatedData))
    );
    yield put(adManagementActions.setStatusDetail(SUCCEEDED));
  } catch (e) {
    const { error: response, scope } = handleError(e?.response || {}, [
      HttpStatus.BAD_REQUEST,
    ]);
    const errors = response?.data?.errors || [];

    yield put(adManagementActions.setErrorDetail(errors, scope));
    yield put(adManagementActions.setStatusDetail(FAILED));
  }
}

function* handleDelete(action) {
  try {
    yield put(adManagementActions.setStatusTable(UPDATING));
    yield call(adManagementServiceApi.deleteAds, action.payload.ids);
    yield put(adManagementActions.getList());
  } catch (e) {
    const { error, scope } = handleError(e?.response || {}, [
      HttpStatus.BAD_REQUEST,
    ]);
    const errors = scope ? error?.data?.errors || [] : [];

    yield put(adManagementActions.setErrorTable(errors, scope));
    yield put(adManagementActions.setStatusTable(FAILED));
  }
}

function* handleDrop(action) {
  try {
    yield put(adManagementActions.setStatusTable(UPDATING));
    yield call(adManagementServiceApi.dropAds, action.payload.ids);
    yield put(adManagementActions.getList());
  } catch (e) {
    const { error, scope } = handleError(e?.response || {}, [
      HttpStatus.BAD_REQUEST,
    ]);
    const errors = scope ? error?.data?.errors || [] : [];

    yield put(adManagementActions.setErrorTable(errors, scope));
    yield put(adManagementActions.setStatusTable(FAILED));
  }
}

function* handleRestore(action) {
  try {
    yield put(adManagementActions.setStatusTable(UPDATING));
    yield call(adManagementServiceApi.restoreAds, action.payload.ids);
    yield put(adManagementActions.getList());
  } catch (e) {
    const { error, scope } = handleError(e?.response || {}, [
      HttpStatus.BAD_REQUEST,
    ]);
    const errors = error?.data?.errors || [];

    yield put(adManagementActions.setErrorTable(scope ? errors : [], scope));
    yield put(adManagementActions.setStatusTable(FAILED));
  }
}

function* handleGetOne(action) {
  const { id, callback } = action.payload;
  try {
    yield put(adManagementActions.setStatusDetail(LOADING));

    const detail = yield call(adManagementServiceApi.getDetail, id);

    yield put(
      adManagementActions.setDetail(convertApiDetailAdManagement(detail))
    );

    yield put(adManagementActions.setStatusDetail(SUCCEEDED));
  } catch (e) {
    callback(true, e?.response?.data?.errors || []);
    yield put(adManagementActions.setStatusDetail(FAILED));
  }
}

function* fetch(page) {
  const filters = yield select(adManagementSettingsSelectors.getFilterSettings);
  const sort = yield select(adManagementSelectors.getSortRequest);
  const tab = yield select(adManagementSelectors.getTab);

  const { detail, count, remain } = yield call(
    adManagementServiceApi.search,
    {
      filters,
      page,
      sort,
      lpoRenegotiation: false,
    },
    tab
  );
  return { detail, count, remain };
}

function* fetchLastPageIfEmpty() {
  const { currentPage } = yield select(adManagementSelectors.getPagination);
  const firstResult = yield fetch(currentPage);
  let secondResult = null;
  let changedToLastPage = false;
  if (firstResult.count > 0 && firstResult.detail.length <= 0) {
    // more items available in previous page
    // calculate final page
    const finalPage = Math.ceil(firstResult.count / TABLE_ROWS_PER_PAGE);
    secondResult = yield fetch(finalPage);
    changedToLastPage = true;
  }

  const result = secondResult || firstResult;
  const { detail, count, remain } = result;

  return { detail, count, remain, changedToLastPage };
}

function* handleFetch() {
  yield put(adManagementActions.setStatusTable(LOADING));

  const {
    detail,
    count,
    remain,
    changedToLastPage,
  } = yield fetchLastPageIfEmpty();

  yield put(
    adManagementActions.setDataTable(
      detail.withFlattenedUrls(),
      count,
      remain,
      changedToLastPage
    )
  );

  yield put(adManagementActions.setStatusTable(SUCCEEDED));
}

function* handleDeleteByFilters(action) {
  try {
    yield put(adManagementActions.setStatusTable(UPDATING));

    const mode = yield select(adManagementSelectors.getTab);
    const filters = yield select(
      adManagementSettingsSelectors.getFilterSettings
    );
    const { excludedIds } = action.payload;

    yield call(
      adManagementServiceApi.deleteByFilters,
      mode,
      filters,
      excludedIds
    );
    yield put(adManagementActions.getList());
  } catch (e) {
    const { error, scope } = handleError(e?.response || {}, [
      HttpStatus.BAD_REQUEST,
    ]);
    const errors = scope ? error?.data?.errors || [] : [];

    yield put(adManagementActions.setErrorTable(errors, scope));
    yield put(adManagementActions.setStatusTable(FAILED));
  }
}

function* handleDownloadCsv() {
  const mode = yield select(adManagementSelectors.getTab);
  const filters = yield select(adManagementSettingsSelectors.getFilterSettings);
  const sort = yield select(adManagementSelectors.getSortRequest);
  // Ad management download csv WITHOUT display items
  yield put(commonActions.setDownloadNotify('CSVファイルを作成しています...'));
  yield call(adManagementServiceApi.downloadCsv, mode, filters, sort);
  yield put(commonActions.setDownloadNotify(''));
}

function* handleDropByFilters(action) {
  try {
    yield put(adManagementActions.setStatusTable(UPDATING));

    const filters = yield select(
      adManagementSettingsSelectors.getFilterSettings
    );
    const { excludedIds } = action.payload;

    yield call(adManagementServiceApi.dropByFilters, filters, excludedIds);
    yield put(adManagementActions.getList());
  } catch (e) {
    const { error, scope } = handleError(e?.response || {}, [
      HttpStatus.BAD_REQUEST,
    ]);
    const errors = scope ? error?.data?.errors || [] : [];

    yield put(adManagementActions.setErrorTable(errors, scope));
    yield put(adManagementActions.setStatusTable(FAILED));
  }
}

function* handleRestoreByFilters(action) {
  try {
    yield put(adManagementActions.setStatusTable(UPDATING));

    const filters = yield select(
      adManagementSettingsSelectors.getFilterSettings
    );
    const { excludedIds } = action.payload;
    yield call(adManagementServiceApi.restoreByFilters, filters, excludedIds);
    yield put(adManagementActions.getList());
  } catch (e) {
    const { error, scope } = handleError(e?.response || {}, [
      HttpStatus.BAD_REQUEST,
    ]);
    const errors = error?.data?.errors || [];

    yield put(adManagementActions.setErrorTable(scope ? errors : [], scope));
    yield put(adManagementActions.setStatusTable(FAILED));
  }
}

function* handleSearchMedia(action) {
  yield put(masterDataActions.requestStart());
  const { searchTerm, selected, agencyId } = action.payload;
  const { items, total } = yield call(
    mediaService.search,
    searchTerm,
    selected,
    OFFSET,
    LIMIT,
    agencyId
  );

  yield put(
    masterDataActions.searchSucceeded({
      total,
      type: 'media_id',
      items: convertArray2Object(items),
    })
  );
}

function* handleSearchAdGroup1(action) {
  yield put(masterDataActions.requestStart());

  const { searchTerm, selected, agencyId } = action.payload;
  const { items, total } = yield call(
    adGroup1Service.search,
    searchTerm,
    selected,
    OFFSET,
    LIMIT,
    agencyId
  );

  yield put(
    masterDataActions.searchSucceeded({
      total,
      type: 'ad_group1_id',
      items: convertArray2Object(items),
    })
  );
}

function* handleSearchAdGroup2(action) {
  yield put(masterDataActions.requestStart());
  const { searchTerm, selected, agencyId } = action.payload;
  const { items, total } = yield call(
    adGroup2Service.search,
    searchTerm,
    selected,
    OFFSET,
    LIMIT,
    agencyId
  );

  yield put(
    masterDataActions.searchSucceeded({
      total,
      type: 'ad_group2_id',
      items: convertArray2Object(items),
    })
  );
}

function* handleSearchMediaAccount(action) {
  yield put(masterDataActions.requestStart());
  const { searchTerm, selected, agencyId } = action.payload;
  const { items, total } = yield call(
    mediaAccountService.search,
    searchTerm,
    selected,
    OFFSET,
    LIMIT,
    agencyId
  );

  yield put(
    masterDataActions.searchSucceeded({
      total,
      type: 'media_account_id',
      items: convertArray2Object(items),
    })
  );
}

function* handleSearchMediaSideCampaign(action) {
  yield put(masterDataActions.requestStart());
  const { searchTerm, selected, agencyId } = action.payload;
  const { items, total } = yield call(
    mediaSideCampaignService.search,
    searchTerm,
    selected,
    OFFSET,
    LIMIT,
    agencyId
  );

  yield put(
    masterDataActions.searchSucceeded({
      total,
      type: 'media_side_campaign_id',
      items: convertArray2Object(items),
    })
  );
}

function* handleSearchMediaSideGroup(action) {
  yield put(masterDataActions.requestStart());
  const { searchTerm, selected, agencyId } = action.payload;
  const { items, total } = yield call(
    mediaSideGroupService.search,
    searchTerm,
    selected,
    OFFSET,
    LIMIT,
    agencyId
  );

  yield put(
    masterDataActions.searchSucceeded({
      total,
      type: 'media_side_group_id',
      items: convertArray2Object(items),
    })
  );
}

function* fetchMasterdataByAgency(action) {
  try {
    yield put(masterDataActions.requestStart());
    const { agencyId } = action.payload;
    const [
      { items: media },
      { items: adGroup1 },
      { items: adGroup2 },
    ] = yield all([
      call(mediaService.search, '', [], OFFSET, LIMIT, agencyId),
      call(adGroup1Service.search, '', [], OFFSET, LIMIT, agencyId),
      call(adGroup2Service.search, '', [], OFFSET, LIMIT, agencyId),
    ]);

    yield all([
      put(masterDataActions.fetchMediaSucceeded(convertArray2Object(media))),
      put(
        masterDataActions.fetchAdGroup1Succeeded(convertArray2Object(adGroup1))
      ),
      put(
        masterDataActions.fetchAdGroup2Succeeded(convertArray2Object(adGroup2))
      ),
    ]);
  } catch (error) {
    yield put(masterDataActions.requestFailed({ error }));
  }
}

function* handleChanegeAd(action) {
  try {
    yield put(adManagementActions.setStatusTable(UPDATING));
    const { data, type, isSelectedAllPages } = action.payload;
    const filters = yield select(
      adManagementSettingsSelectors.getFilterSettings
    );

    yield call(
      adManagementServiceApi.changeAd,
      data,
      type,
      isSelectedAllPages,
      filters
    );
    yield put(adManagementActions.getList());
  } catch (e) {
    const { error, scope } = handleError(e?.response || {}, [
      HttpStatus.BAD_REQUEST,
    ]);
    const errors = scope ? error?.data?.errors || [] : [];

    yield put(adManagementActions.setErrorTable(errors, scope));
    yield put(adManagementActions.setStatusTable(FAILED));
  }
}

function* handleChanegeAdByFilters(action) {
  try {
    yield put(adManagementActions.setStatusTable(UPDATING));
    const { data, type, isSelectedAllPages } = action.payload;
    const filters = yield select(
      adManagementSettingsSelectors.getFilterSettings
    );

    yield call(
      adManagementServiceApi.changeAdByFilters,
      data,
      type,
      isSelectedAllPages,
      filters
    );
    yield put(adManagementActions.getList());
  } catch (e) {
    const { error, scope } = handleError(e?.response || {}, [
      HttpStatus.BAD_REQUEST,
    ]);
    const errors = scope ? error?.data?.errors || [] : [];

    yield put(adManagementActions.setErrorTable(errors, scope));
    yield put(adManagementActions.setStatusTable(FAILED));
  }
}

function* handleContractedLpoBefore() {
  yield put(adManagementActions.setStatusTable(UPDATING));

  const filters = yield select(adManagementSettingsSelectors.getFilterSettings);
  const sort = yield select(adManagementSelectors.getSortRequest);

  const result = yield call(
    adManagementServiceApi.search,
    {
      filters,
      page: 1,
      sort,
      lpoRenegotiation: true,
    },
    'lpo'
  );

  yield put(adManagementActions.setLpoDataCount(result));
}

function* errorHandler(err) {
  const errors = err.response
    ? err.response.data.errors
    : [{ message: err.message }];

  yield put(loggerActions.logError(errors, loggerConstants.LOG_LEVEL_ERROR));
}

export default function* watch() {
  yield takeLatest(
    types.UPLOAD_CSV,
    sharedSagas.safe(errorHandler, handleUploadCsv)
  );
  yield takeLatest(
    types.SUBMIT_CSV,
    sharedSagas.safe(errorHandler, handleSubmitCsv)
  );
  yield takeLatest(
    types.UPLOAD_CSV_DELETE,
    sharedSagas.safe(errorHandler, handleUploadCsvDelete)
  );
  yield takeLatest(
    types.SUBMIT_CSV_DELETE,
    sharedSagas.safe(errorHandler, handleSubmitCsvDelete)
  );
  yield takeLatest(types.CREATE, sharedSagas.safe(errorHandler, handleCreate));
  yield takeLatest(types.UPDATE, sharedSagas.safe(errorHandler, handleUpdate));
  yield takeLatest(types.DELETE, sharedSagas.safe(errorHandler, handleDelete));
  yield takeLatest(
    types.RESTORE,
    sharedSagas.safe(errorHandler, handleRestore)
  );
  yield takeLatest(types.DROP, sharedSagas.safe(errorHandler, handleDrop));
  yield takeLatest(types.GET, sharedSagas.safe(errorHandler, handleGetOne));
  yield takeLatest(types.FETCH, sharedSagas.safe(errorHandler, handleFetch));
  yield takeLatest(
    types.DELETE_BY_FILTERS,
    sharedSagas.safe(errorHandler, handleDeleteByFilters)
  );
  yield takeLatest(
    types.DOWNLOAD_CSV,
    sharedSagas.safe(errorHandler, handleDownloadCsv)
  );
  yield takeLatest(
    types.DROP_BY_FILTERS,
    sharedSagas.safe(errorHandler, handleDropByFilters)
  );
  yield takeLatest(
    types.RESTORE_BY_FILTERS,
    sharedSagas.safe(errorHandler, handleRestoreByFilters)
  );
  yield takeLatest(
    types.SEARCH_MEDIA,
    sharedSagas.safe(errorHandler, handleSearchMedia)
  );
  yield takeLatest(
    types.SEARCH_AD_GROUP1,
    sharedSagas.safe(errorHandler, handleSearchAdGroup1)
  );
  yield takeLatest(
    types.SEARCH_AD_GROUP2,
    sharedSagas.safe(errorHandler, handleSearchAdGroup2)
  );
  yield takeLatest(
    types.FETCH_MASTERDATA_BY_AGENCY,
    sharedSagas.safe(errorHandler, fetchMasterdataByAgency)
  );
  yield takeLatest(
    types.SEARCH_MEDIA_ACCOUNT,
    sharedSagas.safe(errorHandler, handleSearchMediaAccount)
  );
  yield takeLatest(
    types.SEARCH_MEDIA_SIDE_CAMPAIGN,
    sharedSagas.safe(errorHandler, handleSearchMediaSideCampaign)
  );
  yield takeLatest(
    types.SEARCH_MEDIA_SIDE_GROUP,
    sharedSagas.safe(errorHandler, handleSearchMediaSideGroup)
  );
  yield takeLatest(
    types.AD_CHANGE,
    sharedSagas.safe(errorHandler, handleChanegeAd)
  );
  yield takeLatest(
    types.AD_CHANGE_BY_FILTERS,
    sharedSagas.safe(errorHandler, handleChanegeAdByFilters)
  );
  yield takeLatest(
    types.CONTRACTED_LPO_BEFORE,
    sharedSagas.safe(errorHandler, handleContractedLpoBefore)
  );
}
