/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { func, string, shape, bool, number } from 'prop-types';
import React, { useRef } from 'react';
import './filterItem.scss';
import { Badge, OverlayTrigger, Tooltip } from 'react-bootstrap';
import iconCautionYellow from 'assets/images/icon-caution-yellow.svg';
import { isWarningFilter } from 'services/utils';
import { FILTER_KEY_CROSS_DEVICE } from 'services/consts';
import ErrorTooltipWrapper from 'views/atoms/tooltip/ErrorTooltipWrapper';
import { NO_CROSS_PAID } from 'domain/data-export/consts';

const getVariantFilters = (filter, isFilterModifing = false) => {
  if (isFilterModifing) return 'info';
  if (filter.isWarning && !filter.isApply) return 'danger';
  return 'secondary';
};

function FilterItem(props) {
  const {
    filterKey,
    filters,
    text,
    onClose,
    onEdit,
    pageId,
    period,
    crossDeviceReflectionData,
    displayItems,
    updateDisplayItems,
    isFilterModifing,
    isError = false,
    paidContract = NO_CROSS_PAID,
    isAllowRemove = false,
    isRegularReport = false,
    isAcceptedReflectionData = false,
    isAcceptedAccount = false
  } = props;

  const refEl = useRef();
  const filter = isWarningFilter(
    filterKey,
    filters,
    pageId,
    period,
    isAcceptedReflectionData ? crossDeviceReflectionData : [],
    displayItems,
    isRegularReport, // only use in data-export
    paidContract,
    isAcceptedAccount
  );
  const variant = getVariantFilters(filter, isFilterModifing);

  if (filter.isWarning && FILTER_KEY_CROSS_DEVICE === filterKey) {
    // Update display items if there are invalid filters at the time of transition.
    updateDisplayItems();
  }
  const onFilterClick = (el) => {
    if (filterKey !== FILTER_KEY_CROSS_DEVICE) {
      onEdit(el);
    }
  };

  return (
    <Badge
      pill
      variant={variant}
      className="filter-item d-flex align-items-center"
    >
      {!filter.isWarning && (
        <>
          <span
            ref={refEl}
            className="filter-item__text"
            onClick={() => onFilterClick(refEl.current.parentNode)}
          >
            {text}
          </span>
          <button
            className="filter-item__close"
            onClick={onClose}
            type="button"
            aria-label="Close"
          >
            ✕
          </button>
        </>
      )}
      {filter.isWarning && (
        <>
          <span ref={refEl} className="filter-item__danger_text">
            {text}
          </span>
          {isError ? (
            <ErrorTooltipWrapper
              isError={isError}
              errorMess={filter.warningMessage}
              ref={refEl}
            >
              <img src={iconCautionYellow} alt="icon-caution" />
            </ErrorTooltipWrapper>
          ) : (
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip className="dragonball-tooltip variant-warning">
                  {filter.warningMessage}
                </Tooltip>
              }
            >
              <img src={iconCautionYellow} alt="icon-caution" />
            </OverlayTrigger>
          )}

          {isAllowRemove && filterKey === FILTER_KEY_CROSS_DEVICE && (
            <button
              className="filter-item__close filter-item__close-warning"
              onClick={onClose}
              type="button"
              aria-label="Close"
            >
              ✕
            </button>
          )}
        </>
      )}
    </Badge>
  );
}

FilterItem.propTypes = {
  filterKey: string.isRequired,
  filters: shape([]),
  text: string.isRequired,
  onClose: func,
  onEdit: func,
  pageId: string.isRequired,
  period: shape({}),
  displayItems: shape({}),
  paidContract: number,
  isAcceptedReflectionData: bool,
  crossDeviceReflectionData: shape([]),
  updateDisplayItems: func,
  isFilterModifing: bool,
  isError: bool,
  isAllowRemove: bool,
  isRegularReport: bool,
  isAcceptedAccount: bool
};

FilterItem.defaultProps = {
  filters: [],
  onClose: () => {},
  onEdit: () => {},
  period: {},
  displayItems: {},
  paidContract: NO_CROSS_PAID,
  isAcceptedReflectionData: false,
  crossDeviceReflectionData: [],
  updateDisplayItems: () => {},
  isFilterModifing: false,
  isError: false,
  isAllowRemove: false,
  isRegularReport: false,
  isAcceptedAccount: false
};

export default FilterItem;
