// Measurement limit
export const MEASUREMENT_LIMIT_70 = 0.7;
export const MEASUREMENT_LIMIT_85 = 0.85;
export const MEASUREMENT_LIMIT_100 = 1;

// Default config
export const MEASUREMENT_CONFIG_DEFAULT = 0;

// Contract plan
export const LIGHT = 'light';
export const STANDARD = 'standard';
export const AGENCY = 'agency';
export const GSP = 'gsp';
export const STANDARD_1 = 'standard_1';
export const STANDARD_2 = 'standard_2';
export const STANDARD_3 = 'standard_3';
export const STANDARD_4 = 'standard_4';
export const STANDARD_5 = 'standard_5';
export const STANDARD_6 = 'standard_6';
export const AGENCY_1 = 'agency_1';
export const AGENCY_2 = 'agency_2';
export const AGENCY_3 = 'agency_3';
export const AGENCY_4 = 'agency_4';
export const AGENCY_5 = 'agency_5';
export const AGENCY_6 = 'agency_6';
export const AGENCY_7 = 'agency_7';
export const AGENCY_8 = 'agency_8';
export const AGENCY_9 = 'agency_9';
export const LIGHT_CONTRACT_LINK = '/docs/manual/light_contract.pdf';
export const STANDARD_CONTRACT_LINK = '/docs/manual/standard_contract.pdf';
export const AGENCY_CONTRACT_LINK = '/docs/manual/agency_contract.pdf';

export const DISPLAY_CONTRACT_PLAN = {
  [LIGHT]: {
    label: 'ライト',
    link: LIGHT_CONTRACT_LINK,
    file: 'light_contract.pdf',
  },
  [STANDARD_1]: {
    label: 'スタンダード-1',
    link: STANDARD_CONTRACT_LINK,
    file: 'standard_contract.pdf',
  },
  [STANDARD_2]: {
    label: 'スタンダード-2',
    link: STANDARD_CONTRACT_LINK,
    file: 'standard_contract.pdf',
  },
  [STANDARD_3]: {
    label: 'スタンダード-3',
    link: STANDARD_CONTRACT_LINK,
    file: 'standard_contract.pdf',
  },
  [STANDARD_4]: {
    label: 'スタンダード-4',
    link: STANDARD_CONTRACT_LINK,
    file: 'standard_contract.pdf',
  },
  [STANDARD_5]: {
    label: 'スタンダード-5',
    link: STANDARD_CONTRACT_LINK,
    file: 'standard_contract.pdf',
  },
  [STANDARD_6]: {
    label: 'スタンダード-6',
    link: STANDARD_CONTRACT_LINK,
    file: 'standard_contract.pdf',
  },
  [AGENCY_1]: {
    label: 'エージェンシー-1',
    link: AGENCY_CONTRACT_LINK,
    file: 'agency_contract.pdf',
  },
  [AGENCY_2]: {
    label: 'エージェンシー-2',
    link: AGENCY_CONTRACT_LINK,
    file: 'agency_contract.pdf',
  },
  [AGENCY_3]: {
    label: 'エージェンシー-3',
    link: AGENCY_CONTRACT_LINK,
    file: 'agency_contract.pdf',
  },
  [AGENCY_4]: {
    label: 'エージェンシー-4',
    link: AGENCY_CONTRACT_LINK,
    file: 'agency_contract.pdf',
  },
  [AGENCY_5]: {
    label: 'エージェンシー-5',
    link: AGENCY_CONTRACT_LINK,
    file: 'agency_contract.pdf',
  },
  [AGENCY_6]: {
    label: 'エージェンシー-6',
    link: AGENCY_CONTRACT_LINK,
    file: 'agency_contract.pdf',
  },
  [AGENCY_7]: {
    label: 'エージェンシー-7',
    link: AGENCY_CONTRACT_LINK,
    file: 'agency_contract.pdf',
  },
  [AGENCY_8]: {
    label: 'エージェンシー-8',
    link: AGENCY_CONTRACT_LINK,
    file: 'agency_contract.pdf',
  },
  [AGENCY_9]: {
    label: 'エージェンシー-9',
    link: AGENCY_CONTRACT_LINK,
    file: 'agency_contract.pdf',
  },
};

export const LIGHT_LABEL = 'ライト';
export const STANDARD_LABEL = 'スタンダード';
export const AGENCY_LABEL = 'エージェンシー';
export const GSP_1_LABEL = 'Growth Step Program';
export const GSP_2_LABEL = 'スタンダードLE';
export const SERVICE_EBIS_API = 'service_ebis_api';

export const USAGE_STATUS_FIELDS = {
  IS_NEW_SERVICE_PLAN: 'is_new_plan_service',
  CONTRACT_PLAN: 'contract_plan',
  OPTIONS: 'options',
  MEASURE_TRAFFIC_STATUS: 'measure_traffic_status',
  DAILY_MODE: 'daily_mode',
  MONTHLY_MODE: 'monthly_mode',
  USAGE_STATUS_PAST: 'usage_status_past',
};

export const USAGE_STATUS_LIMIT_TAB = 'usage_status_limit';
export const USAGE_STATUS_PAST_TAB = 'usage_status_past';

export const USAGE_STATUS_TAB = {
  USAGE_STATUS_LIMIT_TAB: 'usage_status_limit',
  USAGE_STATUS_PAST_TAB: 'usage_status_past',
};

export const VIEW_FIVE = 'view_five';
export const VIEW_ALL = 'view_all';

export const MEASUREMENT_STATUS_TYPE = {
  DAILY: 'daily',
  MONTHLY: 'monthly',
};

export const MEASUREMENT_SITUATION_FIELDS = {
  CONTRACT_MONTH: 'contract_month',
  MEASUREMENT_CLICK_MAX: 'measurement_click_max',
  MEASUREMENT_PV_MAX: 'measurement_pv_max',
  MEASUREMENT_TOTAL_CLICK: 'measurement_total_click',
  MEASUREMENT_TOTAL_PV: 'measurement_total_pv',
  MEASUREMENT_CLICK: 'measurement_click',
  MEASUREMENT_PV: 'measurement_pv',
  OVER_LIMIT_TOTAL_CLICK: 'over_limit_total_click',
  OVER_LIMIT_TOTAL_PV: 'over_limit_total_pv',
};

export const MSG_NO_USAGE_HISTORY = 'ご利用履歴はございません';

// Measurement situation fields
export const MEASUREMENT_DATE = 'measurementDate';
export const AD_CLICK_CNT = 'adClickCnt';
export const TOTAL_CLICK_ACTUAL = 'totalClickActual';
export const PAGE_VIEW_CNT = 'pageViewCnt';
const TOTAL_PV_ACTUAL = 'totalPVActual';
export const OVER_LIMIT = 'overLimit';

export const DISPLAY_ITEMS_MEASUREMENT_CLICK_DAILY = [
  {
    field: MEASUREMENT_DATE,
    name: '日付',
    width: '108px',
  },
  {
    field: AD_CLICK_CNT,
    name: '実績',
    width: '127px',
  },
  {
    field: TOTAL_CLICK_ACTUAL,
    name: '積み上げ',
    width: '128px',
  },
];

export const DISPLAY_ITEMS_MEASUREMENT_PV_DAILY = [
  {
    field: MEASUREMENT_DATE,
    name: '日付',
    width: '108px',
  },
  {
    field: PAGE_VIEW_CNT,
    name: '実績',
    width: '127px',
  },
  {
    field: TOTAL_PV_ACTUAL,
    name: '積み上げ',
    width: '128px',
  },
];

export const DISPLAY_ITEMS_MEASUREMENT_CLICK_MONTHLY = [
  {
    field: MEASUREMENT_DATE,
    name: '日付',
    width: '108px',
  },
  {
    field: AD_CLICK_CNT,
    name: '実績',
    width: '127px',
  },
  {
    field: OVER_LIMIT,
    name: '超過実績',
    width: '128px',
  },
];

export const DISPLAY_ITEMS_MEASUREMENT_PV_MONTHLY = [
  {
    field: MEASUREMENT_DATE,
    name: '日付',
    width: '108px',
  },
  {
    field: PAGE_VIEW_CNT,
    name: '実績',
    width: '127px',
  },
  {
    field: OVER_LIMIT,
    name: '超過実績',
    width: '128px',
  },
];

// Fields of past usage status tab
export const USAGE_MONTH = 'usageMonth';
const AD_CLICKS = 'adClicks';
const TOTAL_CLICKS = 'totalClicks';
const MINUTES_AGENT = 'minutesAgent';
const UNASSIGNED_AGENT = 'unassignedAgent';
export const PAGE_VIEWS = 'pageViews';
const IMPRESSIONS = 'impressions';

export const DISPLAY_ITEMS_USAGE_STATUS_PAST = [
  {
    field: USAGE_MONTH,
    name: 'ご利用月',
    width: '107px',
  },
  {
    field: AD_CLICKS,
    name: '広告クリック数',
    children: [
      {
        field: TOTAL_CLICKS,
        name: '全体',
        isContentRight: true,
      },
      {
        field: MINUTES_AGENT,
        name: 'エージェント分',
        isContentRight: true,
      },
      {
        field: UNASSIGNED_AGENT,
        name: 'エージェント未割当分',
        isContentRight: true,
      },
    ],
  },
  {
    field: PAGE_VIEWS,
    name: 'サイトPV数',
    isContentRight: true,
  },
  {
    field: IMPRESSIONS,
    name: 'インプレッション数',
    isContentRight: true,
  },
];
