import React, { useMemo, useCallback } from 'react';
import { omit } from 'lodash';
import { objectOf, arrayOf, shape, func, string } from 'prop-types';
import FilterForm from 'views/organism/FilterForm';
import useFilterData from 'services/custom-hooks/useFilterData';
import { DATA_EXPORT } from 'services/routes/constants';
import {
  allFilterableGroup,
  FILTER_GROUP_CONVERSION_EXCLUDE,
  FILTER_GROUP_AD_EXCLUDE,
  FILTER_KEY_CONTACT_HISTORY_AD,
  FILTER_KEY_CONTACT_HISTORY_SEARCH,
  FILTER_KEY_CONTACT_HISTORY_PAGE,
  FILTER_KEY_MEMBER_NAME,
  FILTER_KEY_MEMBER_NAME_EXCLUDE,
  FILTER_KEY_OTHER1,
  FILTER_KEY_OTHER1_EXCLUDE,
  FILTER_KEY_OTHER2,
  FILTER_KEY_OTHER2_EXCLUDE,
  FILTER_KEY_OTHER3,
  FILTER_KEY_OTHER3_EXCLUDE,
  FILTER_KEY_OTHER4,
  FILTER_KEY_OTHER4_EXCLUDE,
  FILTER_KEY_OTHER5,
  FILTER_KEY_OTHER5_EXCLUDE,
  FILTER_KEY_SEARCH_WORD,
  FILTER_KEY_SEARCH_WORD_EXCLUDE,
  FILTER_KEY_CROSS_DEVICE,
} from 'services/consts';
import { REPORT_FIELDS } from 'domain/data-export/consts';
import { FILTER_CONTACT_HISTORY } from 'domain/consts';

const { QUICK_SEARCH } = REPORT_FIELDS;

const fieldsOperatorIncludeOrExact = [
  FILTER_KEY_MEMBER_NAME,
  FILTER_KEY_MEMBER_NAME_EXCLUDE,
  FILTER_KEY_OTHER1,
  FILTER_KEY_OTHER1_EXCLUDE,
  FILTER_KEY_OTHER2,
  FILTER_KEY_OTHER2_EXCLUDE,
  FILTER_KEY_OTHER3,
  FILTER_KEY_OTHER3_EXCLUDE,
  FILTER_KEY_OTHER4,
  FILTER_KEY_OTHER4_EXCLUDE,
  FILTER_KEY_OTHER5,
  FILTER_KEY_OTHER5_EXCLUDE,
  FILTER_KEY_SEARCH_WORD,
  FILTER_KEY_SEARCH_WORD_EXCLUDE,
];

const contactHistoryFields = [
  FILTER_KEY_CONTACT_HISTORY_AD,
  FILTER_KEY_CONTACT_HISTORY_SEARCH,
  FILTER_KEY_CONTACT_HISTORY_PAGE,
];

const FilterSetting = (props) => {
  const { reportType, selected, permissions, onChange } = props;

  const [filterableList, mergedMasterData] = useFilterData(
    DATA_EXPORT,
    reportType
  );

  const filterableListByGroup = useMemo(() => {
    const filter = Object.keys(filterableList).reduce((acc, key) => {
      const filterable = { ...filterableList[key] };
      const { order, group = '' } = filterable;
      if (fieldsOperatorIncludeOrExact.includes(key)) {
        filterable.multiple = true;
        filterable.maxItem = 2;
        filterable.note = 'カンマ区切りで複数指定可';
      }

      return {
        ...acc,
        [key]: {
          ...filterable,
          order: order + (allFilterableGroup[group]?.order || 0),
          subTitle: [
            FILTER_GROUP_CONVERSION_EXCLUDE,
            FILTER_GROUP_AD_EXCLUDE,
          ].includes(group)
            ? allFilterableGroup[group]?.title
            : '',
        },
      };
    }, filterableList);

    return { ...filter, ...allFilterableGroup };
  }, [filterableList]);

  const defaultFilters = useMemo(() => {
    const filter = { ...selected };
    // Set default for contact history
    contactHistoryFields.forEach((item) => {
      if (item in filterableList) {
        filter[item] = {
          order: filterableList[item].order,
          type: filterableList[item].type,
          values: filter[item]
            ? filter[item].values
            : Object.keys(FILTER_CONTACT_HISTORY),
        };
      }
    });

    return filter;
  }, [filterableList, selected]);

  const { selectedFilters, keepFilters } = useMemo(() => {
    return Object.keys(defaultFilters).reduce(
      (acc, key) => {
        if (key in filterableList) {
          return {
            ...acc,
            selectedFilters: {
              ...acc.selectedFilters,
              [key]: defaultFilters[key],
            },
          };
        }
        return {
          ...acc,
          keepFilters: { ...acc.keepFilters, [key]: defaultFilters[key] },
        };
      },
      { selectedFilters: {}, keepFilters: {} }
    );
  }, [filterableList, defaultFilters]);

  const handleUpdateFilters = useCallback(
    (filters) => {
      // Not allow update filter when switch report type
      const filterKeys = Object.keys(filters);
      if (
        filterKeys.length === 1 &&
        contactHistoryFields.includes(filterKeys[0])
      ) {
        return;
      }
      onChange(QUICK_SEARCH, { ...keepFilters, ...filters });
    },
    [keepFilters, onChange]
  );

  return (
    <div className="data-export-setting__item data-export-setting__item--filter mb-30">
      <div className="data-export-setting__title">フィルタ</div>
      <div className="data-export-setting__content d-block">
        <FilterForm
          customView
          masterData={mergedMasterData}
          filterableList={filterableListByGroup}
          selectedFilters={selectedFilters}
          onChange={handleUpdateFilters}
          userPermissions={permissions}
          pageId={DATA_EXPORT}
          currentTab={reportType}
          listFilterHidden={[FILTER_KEY_CROSS_DEVICE]}
        />
      </div>
    </div>
  );
};

FilterSetting.propTypes = {
  reportType: string,
  selected: objectOf(shape({})),
  permissions: arrayOf(string),
  onChange: func,
};

FilterSetting.defaultProps = {
  reportType: '',
  selected: {},
  permissions: [],
  onChange: () => {},
};

export default React.memo(FilterSetting);
