import {
  API_DATE_FORMAT,
  CALENDAR_DAY_FORMAT,
  CHART_EXPORT_IMAGE_ID,
  COMMA_SEPARATOR,
  COMPARED_PERIOD_AVAILABLE_PRESETS,
  CURRENT_PERIOD_AVAILABLE_PRESETS,
  DATE_RANGE_TYPE,
  HIGHCHARTS_EXPORT_EXCLUDED_CLASSLIST,
  ONE_BYTE_COMMA_SEPERATOR,
  UPDATE_TIME_FORMAT,
  UNLIMITED_VALUE,
} from 'domain/consts';
import { SORT_DIRECTION_ASC } from 'domain/settings/display-items';
import { getFieldApiResponse, formatNumber } from 'domain/utils';
import { CONTENT_CATEGORY } from 'domain/fields';
import html2canvas from 'html2canvas';
import _ from 'lodash';
import isArray from 'lodash/isArray';
import moment from 'moment';
import { REGEX_SPLIT_BY_COMMA } from 'services/regexPatterns';
import {
  CATEGORY_ANALYZE,
  COMPARE_PERIOD,
  COST_ALLOCATION,
  APP_HELP_UPLOAD_CSV_TOO_MAX_ERROR,
  TAG_MANAGEMENT_CONTENT_CATEGORY,
  LOG_PAGE_ANALYZE,
  LOG_PERIOD_ANALYZE,
  LOG_ROUTE_ANALYZE,
  APP_HELP_DNS_SETTING,
  LPO_PERIOD,
  DATA_EXPORT,
} from 'services/routes/constants';

import isEmpty from 'lodash/isEmpty';

import HttpStatus from 'domain/httpUtils';

import TableTooltips from 'services/common/TableTooltips';
import {
  FILTER_KEY_CROSS_DEVICE,
  FILTER_KEY_CHANNEL,
  FILTER_KEY_PAGE_ID,
  FILTER_KEY_PAGE_TITLE,
  FILTER_KEY_PAGE_URL,
  FILTER_KEY_DIRECTORY,
  FILTER_KEY_CONTENT_CATEGORY,
  FILTER_KEY_SEARCH_CONSOLE,
} from 'services/consts';
import {
  FilterConfig,
  HiddenFilterConfig,
} from 'domain/commonbar/FilterConfig';
import { EXPORT_IMAGE_ROUTE } from 'domain/log/route-analyze/consts';
import { CROSS_PAID, NO_CROSS_PAID } from 'domain/data-export/consts';
import { checkAggregationByDirectory } from './log/pageAnalyzeServices';

const getRandomId = (prefix = '', suffix = '') => {
  const randomKey = Math.random();
  return `${prefix}-${randomKey}${suffix ? `-${suffix}` : ''}`;
};

const equalsAllKeys = (src, dest) =>
  Object.keys(src).every((key) => src[key] === dest[key]);

const containsByAllKeys = (list, item) =>
  list.some((elem) =>
    Object.keys(elem).every((key) => elem[key] === item[key])
  );

const convertBigNumber = (n, fractionalDigits) => {
  const val = { value: n, unit: '' };
  let option = Number.isNaN(fractionalDigits)
    ? undefined
    : {
        minimumFractionDigits: fractionalDigits,
        maximumFractionDigits: fractionalDigits,
      };
  if (Number.isNaN(n)) {
    return n;
  }
  if (n >= 10000 && n < 100000000) {
    val.value = n / 10000;
    val.unit = '万';
  }
  if (n >= 100000000) {
    val.value = n / 100000000;
    val.unit = '億';
  }
  if (n < 10000) {
    option = {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    };
  }
  return val.value.toLocaleString('en-US', option) + val.unit;
};

/**
 * Get start date and end date of specified DATE_RANGE_TYPE
 * @param {number} type
 * @param {{startDate: moment.Moment, endDate: moment.Moment}} withPeriod
 * @return {{startDate: moment.Moment, endDate: moment.Moment}}
 */
const dateRangeOf = (type, withPeriod = null) => {
  let startDate;
  let endDate;
  const mondayIndex = 1;
  const sundayIndex = 7;
  const fridayIndex = 5;

  const throwErrorIfWithPeriodEmpty = () => {
    if (!withPeriod) {
      throw new Error('withPeriod must not be empty');
    }
  };

  switch (type) {
    case DATE_RANGE_TYPE.TODAY: {
      startDate = moment();
      endDate = moment();
      break;
    }
    case DATE_RANGE_TYPE.YESTERDAY: {
      startDate = moment().add(-1, 'day');
      endDate = moment().add(-1, 'day');
      break;
    }
    case DATE_RANGE_TYPE.THIS_WEEK: {
      const dow = moment().isoWeekday();
      const mondayOffset = dow - mondayIndex;
      startDate = moment().subtract(mondayOffset, 'days');
      endDate = moment();
      break;
    }
    case DATE_RANGE_TYPE.LAST_WEEK: {
      const dow = moment().isoWeekday(); // 1: Monday
      const mondayOffset = dow - mondayIndex;
      // startDate is last monday
      startDate = moment().subtract(7 + mondayOffset, 'days');
      // endDate is sunday
      const sundayOffset = sundayIndex - dow;
      endDate = moment().subtract(7 - sundayOffset, 'days');
      break;
    }
    case DATE_RANGE_TYPE.THIS_MONTH: {
      startDate = moment().startOf('month');
      endDate = moment();
      break;
    }
    case DATE_RANGE_TYPE.LAST_MONTH: {
      startDate = moment().add(-1, 'month').startOf('month');
      endDate = moment().add(-1, 'month').endOf('month');
      break;
    }
    case DATE_RANGE_TYPE.DAYS_AGO_7_INCLUDED:
      startDate = moment().subtract(6, 'days');
      endDate = moment();
      break;
    case DATE_RANGE_TYPE.DAYS_AGO_14_INCLUDED:
      startDate = moment().subtract(13, 'days');
      endDate = moment();
      break;
    case DATE_RANGE_TYPE.DAYS_AGO_30_INCLUDED:
      startDate = moment().subtract(29, 'days');
      endDate = moment();
      break;
    case DATE_RANGE_TYPE.DAYS_AGO_7_EXCLUDED:
      startDate = moment().subtract(7, 'days');
      endDate = moment().subtract(1, 'days');
      break;
    case DATE_RANGE_TYPE.DAYS_AGO_14_EXCLUDED:
      startDate = moment().subtract(14, 'days');
      endDate = moment().subtract(1, 'days');
      break;
    case DATE_RANGE_TYPE.DAYS_AGO_30_EXCLUDED:
      startDate = moment().subtract(30, 'days');
      endDate = moment().subtract(1, 'days');
      break;
    case DATE_RANGE_TYPE.THIS_MONTH_EXCLUDED:
      startDate = moment().startOf('month');
      endDate = moment().date() > 1 ? moment().subtract(1, 'days') : startDate;
      break;
    case DATE_RANGE_TYPE.LAST_WEEK_BUSINESS_DAYS: {
      const dow = moment().isoWeekday(); // 1: Monday
      const mondayOffset = dow - mondayIndex;
      // startDate is last monday
      startDate = moment().subtract(7 + mondayOffset, 'days');
      // endDate is Friday
      const fridayOffset = fridayIndex - dow;
      endDate = moment().subtract(7 - fridayOffset, 'days');
      break;
    }
    case DATE_RANGE_TYPE.LAST_PERIOD: {
      throwErrorIfWithPeriodEmpty();
      endDate = moment(withPeriod.startDate).subtract(1, 'day');
      const dayDiff = withPeriod.endDate.diff(withPeriod.startDate, 'days');
      startDate = moment(endDate).subtract(dayDiff, 'days');
      break;
    }
    case DATE_RANGE_TYPE.LAST_YEAR_SAME_PERIOD:
      throwErrorIfWithPeriodEmpty();
      endDate = moment(withPeriod.endDate).subtract(1, 'year');
      startDate = moment(withPeriod.startDate).subtract(1, 'year');
      break;
    case DATE_RANGE_TYPE.LAST_MONTH_THIS_PERIOD: {
      throwErrorIfWithPeriodEmpty();
      startDate = moment(withPeriod.startDate)
        .add(-1, 'month')
        .startOf('month');
      endDate = moment(withPeriod.startDate).add(-1, 'month').endOf('month');
      break;
    }
    case DATE_RANGE_TYPE.LAST_WEEK_THIS_PERIOD: {
      throwErrorIfWithPeriodEmpty();
      const dow = moment(withPeriod.startDate).isoWeekday(); // 1: Monday
      const mondayOffset = dow - mondayIndex;
      // startDate is last monday
      startDate = moment(withPeriod.startDate).subtract(
        7 + mondayOffset,
        'days'
      );
      // endDate is sunday
      const sundayOffset = sundayIndex - dow;
      endDate = moment(withPeriod.startDate).subtract(7 - sundayOffset, 'days');
      break;
    }
    default:
      throw new Error(`Invalid DATE_RANGE_TYPE: ${type}`);
  }

  return {
    startDate: startDate.startOf('day'),
    endDate: endDate.startOf('day'),
  };
};

/**
 *
 * @param {{startDate, endDate}}period
 * @param type
 */
const periodMatchDateRangeType = (period, type) => {
  const { startDate, endDate } = dateRangeOf(type);
  return (
    startDate.isSame(period.startDate, 'date') &&
    endDate.isSame(period.endDate, 'date')
  );
};

const communicationStatus = {
  IDLE: 'idle',
  LOADING: 'loading',
  UPDATING: 'updating',
  SUCCEEDED: 'succeeded',
  FAILED: 'failed',
  DOWNLOADING: 'downloading',
};

const LOADING_FINISH_STATUSES = [
  communicationStatus.SUCCEEDED,
  communicationStatus.FAILED,
];

const ErrorCode = {
  OVER_USER: 'OVER_USER',
  NOT_EXISTS_DB: 'NOT_EXISTS_DB',
};

const getIndexOfArrayObject = (data, key, value) => {
  return data
    .map((item) => {
      return item[key];
    })
    .indexOf(value);
};

const isArrayEmpty = (arrayValue) => {
  if (!Array.isArray(arrayValue) || !arrayValue.length) {
    return true;
  }

  return false;
};

const isObjectEmpty = (objectValue) => {
  if (!objectValue || !Object.keys(objectValue).length) {
    return true;
  }

  return false;
};

const isStringEmpty = (stringValue) => {
  return _.isEqualWith(['', null, undefined], stringValue, (arrs, value) =>
    arrs.includes(value)
  );
};

/**
 * @param {string} channel
 * @param {array} dimensions
 * @param {array} metrics
 * @param {{field:string,direction:string}} sorts
 * @param {object} itemsConfig
 * @return {array}
 */
const createTableHeaders = ({
  channel,
  dimensions,
  metrics,
  sorts,
  itemsConfig,
}) => {
  const dimensionColumns = itemsConfig.dimensions
    .filter(
      (dimension) =>
        dimensions.includes(dimension.name) &&
        (channel === dimension.tab || !dimension.tab)
    )
    .map((dimension) => ({
      ...dimension,
      isDimension: true,
      sort: sorts.reduce((acc, { field, direction }) => {
        if (field === dimension.name) {
          return direction;
        }
        return acc;
      }, 'none'),
    }));

  const metricColumns = itemsConfig.metrics
    .filter(
      (metric) =>
        metrics.includes(metric.name) && (channel === metric.tab || !metric.tab)
    )
    .map((metric) => ({
      ...metric,
      isDimension: false,
      sort: sorts.reduce((acc, { field, direction }) => {
        if (field === metric.name) {
          return direction;
        }
        return acc;
      }, 'none'),
    }));

  const headers = dimensionColumns.concat(metricColumns);

  const headerGroup = headers.map((header) => {
    const group = itemsConfig.groups.find((item) =>
      item.children.includes(header.name)
    );
    if (group) {
      return {
        ...group,
        isDimension: header.isDimension,
        children: headers
          .filter((item) => group.children.includes(item.name))
          .map((item) => ({
            ...item,
            sort:
              item.sort === false
                ? false
                : sorts.reduce((acc, { field, direction }) => {
                    if (field === item.name) {
                      return direction;
                    }
                    return acc;
                  }, 'none'),
          })),
      };
    }
    if (!isArrayEmpty(header.children)) {
      return {
        ...header,
        isDimension: header.isDimension,
        children: header.children.map((item) => ({
          ...item,
          sort:
            item.sort === false
              ? false
              : sorts.reduce((acc, { field, direction }) => {
                  if (field === item.name) {
                    return direction;
                  }
                  return acc;
                }, 'none'),
        })),
      };
    }

    return { ...header };
  });

  return headerGroup.reduce((header, current) => {
    if (
      !header.find((item) => item.name === current.name) &&
      (!('children' in current) || current.children.length !== 0)
    ) {
      return header.concat([current]);
    }

    return header;
  }, []);
};

/**
 * @param {string{}} data
 * @param {string[]} dimensionsDisplay
 * @param {string[]} dimensionsConfig
 * @return {string{}}
 */
const createDimensionKeyObject = (data, dimensionsDisplay, dimensionsConfig) =>
  dimensionsConfig
    .filter((dimension) => dimensionsDisplay.includes(dimension.name))
    .map((dimension) => dimension)
    .reduce((acc, { name }) => {
      const field = getFieldApiResponse(name);
      acc[field.key] = data[field.key];
      return acc;
    }, {});

const buildDimensionsKeyAsString = (dimensionKey) =>
  Object.keys(dimensionKey)
    .sort((d1, d2) => d1.localeCompare(d2))
    .map((k) => `${k}:${dimensionKey[k]}`)
    .join('--');

/**
 * @param {string field
 * @param {string[]} prefixs
 * @return {string}
 */
const getPrefix = (field, prefixs) => prefixs.find((e) => field.startsWith(e));
const getSuffix = (field, prefixs) =>
  field.replace(getPrefix(field, prefixs), '');

const saveAs = async (uri, filename) => {
  const link = document.createElement('a');

  if (typeof link.download === 'string') {
    link.href = uri;
    link.download = filename;

    // Firefox requires the link to be in the body
    document.body.appendChild(link);

    // simulate click
    link.click();

    // remove the link when done
    document.body.removeChild(link);
  } else {
    window.open(uri);
  }
};

const exportImage = async (screenPath, callback = () => {}) => {
  switch (screenPath) {
    case CATEGORY_ANALYZE:
    case COST_ALLOCATION:
    case LOG_PERIOD_ANALYZE:
    case COMPARE_PERIOD: {
      const chartExportArea = document.getElementById(CHART_EXPORT_IMAGE_ID);

      if (!chartExportArea) {
        return;
      }
      const existingWidth = chartExportArea.style.width;
      chartExportArea.style.width = `${chartExportArea.offsetWidth}px`;
      html2canvas(chartExportArea, {
        ignoreElements: (elem) =>
          HIGHCHARTS_EXPORT_EXCLUDED_CLASSLIST.some((clsName) =>
            elem.classList.contains(clsName)
          ),
        scrollY: -window.scrollY,
        scrollX: -window.scrollX,
        allowTaint: true,
      }).then((canvas) => {
        chartExportArea.style.width = existingWidth;
        callback(canvas);
      });
      break;
    }
    case LPO_PERIOD: {
      const chartExportArea = document.getElementById(CHART_EXPORT_IMAGE_ID);

      if (!chartExportArea) {
        return;
      }
      const existingWidth = chartExportArea.style.width;
      chartExportArea.style.width = `${chartExportArea.offsetWidth}px`;
      html2canvas(chartExportArea, {
        ignoreElements: (elem) =>
          HIGHCHARTS_EXPORT_EXCLUDED_CLASSLIST.some((clsName) =>
            elem.classList.contains(clsName)
          ),
        scrollY: -window.scrollY,
        scrollX: -window.scrollX,
        allowTaint: true,
      }).then((canvas) => {
        chartExportArea.style.width = existingWidth;
        callback(canvas);
      });
      break;
    }
    case LOG_ROUTE_ANALYZE: {
      const exportArea = document.getElementById(EXPORT_IMAGE_ROUTE);
      if (!exportArea) return;

      exportArea.classList.add(`route--${EXPORT_IMAGE_ROUTE}`);
      const existingWidth = exportArea.scrollWidth;
      const existingHeight = exportArea.scrollHeight;

      html2canvas(exportArea, {
        scrollY: -window.scrollY,
        scrollX: -window.scrollX,
        allowTaint: true,
        width: existingWidth,
        height: existingHeight,
        imageTimeout: 0,
      }).then((canvas) => {
        exportArea.classList.remove(`route--${EXPORT_IMAGE_ROUTE}`);
        callback(canvas);
      });

      break;
    }
    default:
      break;
  }
};

const getToday = () => moment().startOf('day');

const formatDateOnCreationData = (
  momentObj,
  defaultFormat = CALENDAR_DAY_FORMAT
) => {
  if (!momentObj) return null;
  let { format } = momentObj.creationData();
  if (!format) {
    format = defaultFormat;
  }

  return momentObj.format(format);
};

const strToArray = (text) => {
  let values = text.split(',');
  values = values.map((item) => item.trim());
  return values;
};

const arrToStr = (
  arr,
  jpComma = false,
  limit = UNLIMITED_VALUE,
  separator = `${ONE_BYTE_COMMA_SEPERATOR} `
) => {
  if (!isArray(arr) || arr.length === 0) return '';
  if (arr.length === 1) return arr[0];

  const actualSeparator = jpComma ? COMMA_SEPARATOR : separator;

  if (limit > UNLIMITED_VALUE && arr.length > limit) {
    return `${arr.slice(0, limit).join(actualSeparator)}${actualSeparator}...`;
  }

  return arr.join(actualSeparator);
};

const timezoneFormat = (date) => {
  let periodMonth;

  if (isEmpty(date)) {
    periodMonth = moment().format(UPDATE_TIME_FORMAT);
  } else {
    periodMonth = moment(date, 'YYYY-MM-DDTHH:mm:ssZ')
      .tz('Asia/Tokyo')
      .format(UPDATE_TIME_FORMAT);
  }

  return periodMonth;
};

const getStartEndDate = (date) => {
  const startDate = moment(date, UPDATE_TIME_FORMAT)
    .startOf('month')
    .format(UPDATE_TIME_FORMAT);
  const endDate = moment(date, UPDATE_TIME_FORMAT)
    .endOf('month')
    .format(UPDATE_TIME_FORMAT);

  const start = moment(startDate, UPDATE_TIME_FORMAT);
  const end = moment(endDate, UPDATE_TIME_FORMAT);

  return { start, end };
};

const getDefaultStartEndDate = () => {
  return getStartEndDate(moment().format(UPDATE_TIME_FORMAT));
};

// Split the string into array separated by one byte and two bytes comma, trimmed
const splitStringToArrayByComma = (str, hasTrim = true) => {
  const arrs = str.split(REGEX_SPLIT_BY_COMMA);
  return arrs.map((val) => (hasTrim || !val.trim().length ? val.trim() : val));
};

const getDiffByMonth = (start, end) => {
  const totalMonthsFromStart = start.month() + start.year() * 12;
  const totalMonthsFromEnd = end.month() + end.year() * 12;
  return totalMonthsFromEnd - totalMonthsFromStart;
};

const clonePeriod = (period) => {
  return {
    ...period,
    start: period.start?.clone(),
    end: period.end?.clone(),
  };
};

/**
 * Check valid date
 * @param {string} dateString
 * @param {array} formats default with format YYYY-MM-DD, YYYY/MM/DD
 * @return {bool}
 */
const isValidDate = (
  dateString,
  formats = [API_DATE_FORMAT, CALENDAR_DAY_FORMAT]
) => {
  const date = isEmpty(formats)
    ? moment(dateString)
    : moment(dateString, formats, true);
  return date.isValid();
};

/**
 * Check a date as the current month
 * @param {string}dateString with format YYYY-MM-DD, YYYY/MM/DD
 * @return {bool}
 */
const isCurrentMonth = (dateString) => {
  if (isValidDate(dateString)) {
    const date = moment(dateString);
    return date.year() === moment().year() && date.month() === moment().month();
  }
  return false;
};

/**
 * Check a date as the last month
 * @param {string}dateString with format YYYY-MM-DD, YYYY/MM/DD
 * @return {bool}
 */
const isLastMonth = (dateString) => {
  return (
    isValidDate(dateString) &&
    moment().subtract(1, 'months').format('YYYY-MM') ===
      moment(dateString).format('YYYY-MM')
  );
};

const isWarningFilter = (
  filterKey,
  filters,
  screenId,
  period,
  crossDeviceReflectionData = [],
  displayItems = {},
  isRegularReport = false,
  paidContract = NO_CROSS_PAID,
  isAcceptedAccount = false
) => {
  const defaultInfo = { isWarning: false, warningMessage: null };
  const channels = _.get(filters, `${FILTER_KEY_CHANNEL}.values`, []);
  let hiddenFilters = HiddenFilterConfig[filterKey] || [];

  if (isRegularReport && filterKey === FILTER_KEY_CROSS_DEVICE) {
    hiddenFilters = [...HiddenFilterConfig[filterKey], DATA_EXPORT];
  }

  // apply only for data-export
  if (filterKey === FILTER_KEY_CROSS_DEVICE) {
    if (
      'isCheckRegularPeriod' in period &&
      period.isCheckRegularPeriod &&
      paidContract === CROSS_PAID &&
      isAcceptedAccount
    ) {
      return defaultInfo;
    }
    if (isRegularReport && paidContract !== CROSS_PAID && isAcceptedAccount) {
      return {
        isWarning: true,
        warningMessage: 'クロスデバイス（有償版）のご契約が必要です ',
      };
    }
  }

  switch (filterKey) {
    case FILTER_KEY_CHANNEL:
      return {
        isWarning:
          hiddenFilters.includes(screenId) &&
          FILTER_KEY_CHANNEL in filters &&
          channels.includes(FilterConfig.summaryChannels[0].value),
        warningMessage: TableTooltips.crossDeviceWarningMessages['PATTERN-C'],
      };
    case FILTER_KEY_CROSS_DEVICE:
      if (!hiddenFilters.includes(screenId)) {
        return {
          isWarning: true,
          warningMessage: TableTooltips.crossDeviceWarningMessages['PATTERN-A'],
        };
      }
      if (
        FILTER_KEY_CHANNEL in filters &&
        channels.includes(FilterConfig.summaryChannels[0].value)
      ) {
        return {
          isWarning: true,
          warningMessage: TableTooltips.crossDeviceWarningMessages['PATTERN-D'],
        };
      }
      if (Object.keys(crossDeviceReflectionData).length === 0) {
        if (
          isRegularReport &&
          paidContract === CROSS_PAID &&
          isAcceptedAccount
        ) {
          return {
            isWarning: true,
            warningMessage:
              TableTooltips.crossDeviceWarningMessages['PATTERN-B-DATA-EXPORT'],
          };
        }
        return {
          isWarning: true,
          warningMessage: TableTooltips.crossDeviceWarningMessages['PATTERN-B'],
        };
      }
      if (
        !period.start ||
        !period.end ||
        !crossDeviceReflectionData[0].from_date ||
        !crossDeviceReflectionData[0].to_date
      ) {
        if (
          isRegularReport &&
          paidContract === CROSS_PAID &&
          isAcceptedAccount
        ) {
          return {
            isWarning: true,
            warningMessage:
              TableTooltips.crossDeviceWarningMessages['PATTERN-B-DATA-EXPORT'],
          };
        }
        return {
          isWarning: true,
          warningMessage: TableTooltips.crossDeviceWarningMessages['PATTERN-B'],
        };
      }
      if (
        crossDeviceReflectionData[0].from_date >
          period.start.format('YYYY-MM-DD') ||
        crossDeviceReflectionData[0].to_date < period.end.format('YYYY-MM-DD')
      ) {
        if (
          isRegularReport &&
          paidContract === CROSS_PAID &&
          isAcceptedAccount
        ) {
          return {
            isWarning: true,
            warningMessage:
              TableTooltips.crossDeviceWarningMessages['PATTERN-B-DATA-EXPORT'],
          };
        }
        return {
          isWarning: true,
          warningMessage: TableTooltips.crossDeviceWarningMessages['PATTERN-B'],
        };
      }
      return {
        isWarning: false,
        warningMessage: null,
      };
    case FILTER_KEY_DIRECTORY: {
      const isAggregationByDirectory = checkAggregationByDirectory(
        displayItems
      );
      if (screenId === LOG_PAGE_ANALYZE && !isAggregationByDirectory) {
        return {
          isWarning: true,
          warningMessage: `集計軸にディレクトリが含まれていないため、ディレクトリで絞り込みすることはできません`,
        };
      }

      return defaultInfo;
    }

    case FILTER_KEY_PAGE_ID:
    case FILTER_KEY_PAGE_TITLE:
    case FILTER_KEY_PAGE_URL:
    case FILTER_KEY_CONTENT_CATEGORY: {
      const isAggregationByDirectory = checkAggregationByDirectory(
        displayItems
      );
      if (screenId === LOG_PAGE_ANALYZE && isAggregationByDirectory) {
        return {
          isWarning: true,
          warningMessage: `集計軸がディレクトのため、${filters[filterKey].title}で絞り込みすることはできません`,
        };
      }

      return defaultInfo;
    }
    case FILTER_KEY_SEARCH_CONSOLE: {
      if (displayItems?.search_word) {
        return {
          isWarning: true,
          warningMessage:
            'ランディングページ分析の集計軸に「検索ワード」が含まれているため、Google Search Consoleのデータが反映されます。',
          // this key for handle case show warning but the background color is secondary => apply
          isApply: true,
        };
      }
      return defaultInfo;
    }
    default:
      return defaultInfo;
  }
};

const getPairType = (pairType) => {
  if (pairType === 'predict') {
    return '機械学習';
  }
  return 'ユーザー名';
};

export {
  HttpStatus,
  ErrorCode,
  communicationStatus,
  getIndexOfArrayObject,
  isArrayEmpty,
  isObjectEmpty,
  isStringEmpty,
  getRandomId,
  equalsAllKeys,
  containsByAllKeys,
  convertBigNumber,
  dateRangeOf,
  periodMatchDateRangeType,
  createTableHeaders,
  createDimensionKeyObject,
  getPrefix,
  getSuffix,
  buildDimensionsKeyAsString,
  exportImage,
  saveAs,
  getToday,
  formatDateOnCreationData,
  LOADING_FINISH_STATUSES,
  strToArray,
  arrToStr,
  timezoneFormat,
  getStartEndDate,
  getDefaultStartEndDate,
  splitStringToArrayByComma,
  getDiffByMonth,
  clonePeriod,
  isValidDate,
  isCurrentMonth,
  isLastMonth,
  isWarningFilter,
  getPairType,
};

export const getCurrentPeriodDates = ({ start, end, preset }) => {
  let currentPeriod;
  if (preset && CURRENT_PERIOD_AVAILABLE_PRESETS.includes(preset)) {
    const { startDate, endDate } = dateRangeOf(preset);
    currentPeriod = {
      start: startDate,
      end: endDate,
      preset,
    };
  } else {
    currentPeriod = {
      start: moment(start),
      end: moment(end),
      preset: DATE_RANGE_TYPE.CUSTOM,
    };
  }
  return currentPeriod;
};
export const getComparedPeriodDates = (
  { start, end, preset, enabled },
  currentPeriod
) => {
  const updatedComparedPeriod = {
    enabled,
  };
  if (!enabled) {
    updatedComparedPeriod.start = start ? moment(start) : start;
    updatedComparedPeriod.end = end ? moment(end) : end;
    updatedComparedPeriod.preset = preset;
  } else {
    // eslint-disable-next-line no-lonely-if
    if (!COMPARED_PERIOD_AVAILABLE_PRESETS.includes(preset)) {
      updatedComparedPeriod.preset = DATE_RANGE_TYPE.CUSTOM;
      updatedComparedPeriod.start = start ? moment(start) : start;
      updatedComparedPeriod.end = end ? moment(end) : end;
    } else {
      updatedComparedPeriod.preset = preset;
      const { startDate, endDate } = dateRangeOf(preset, {
        startDate: currentPeriod.start,
        endDate: currentPeriod.end,
      });
      updatedComparedPeriod.start = startDate;
      updatedComparedPeriod.end = endDate;
    }
  }

  return updatedComparedPeriod;
};

export const retry = (fn, retriesLeft = 5, interval = 1000) => {
  return new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            // reject('maximum retries exceeded');
            reject(error);
            return;
          }

          // Passing on "reject" is the important part
          retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
};

export const removeNewLineTokens = (str) => {
  return str.replace(/(?:\r|\n)/g, '');
};
/**
 * Format message. Parameters are enclosed with brackets {} tokens
 * @param {string} msg
 * @param {Record<string, any>} params
 * @return string
 */
export const formatMessage = (msg, params = {}) => {
  return Object.entries(params).reduce((acc, [key, value]) => {
    const tokenRegex = new RegExp(`{${key}}`, 'g');
    return _.replace(acc, tokenRegex, value);
  }, msg);
};

export const getFinalPage = (totalItems, rowsPerPage) =>
  Math.ceil(totalItems / rowsPerPage) || 1;

export const checkFormatErrorByLine = (error) => {
  if (_.isEmpty(error)) return false;

  if (error.lines) return true;
  const [text, line] = error.field.split('.');
  return text === 'line' && !_.isNaN(line);
};

export const getParamByErrorCode = (error) => {
  if (_.isEmpty(error)) return {};

  const { code, metadata } = error;
  const params = metadata?.param || [];

  const param = params.reduce((acc, value, index) => {
    acc[`index.${index}`] = value;
    acc.length = formatNumber(value);
    return acc;
  }, {});

  switch (code) {
    case 'TOO_MAX_ERROR':
    case 'OVER_MAX_ERROR': {
      const [length] = params;
      param.length = length || 200;
      param.link = APP_HELP_UPLOAD_CSV_TOO_MAX_ERROR;
      break;
    }
    case 'FILE_MAX_LINE_CHECK':
    case 'LIMIT_AD_CHECK':
    case 'OVER_MAX_LINE':
    case 'OVER_MAX_ROW': {
      const [length] = params;
      param.length = formatNumber(length);
      break;
    }
    case 'FILE_SIZE_CHECK':
    case 'OVER_MAX_SIZE': {
      const [size] = params;
      param.size = size;
      break;
    }
    case 'FILE_EXTENSION_CHECK':
      param.extension = params.join('・');
      break;
    case 'GENERIC_ERROR_CHECK':
      param.link = APP_HELP_DNS_SETTING;
      break;
    default:
      break;
  }

  return param;
};

/**
 * @param {array} errors
 * @param {object} message
 * @param {object} options
 * @return {object}
 */
export const getErrorMessageByCode = (error, message, options) => {
  if (_.isEmpty(error)) return '';

  const { code } = error;
  const param = getParamByErrorCode(error);
  const replace = { ...param, ...options };

  return formatMessage(message[code], { ...replace });
};

export const formatErrorValidate = (errors, messages, fields) => {
  if (_.isEmpty(errors)) return [];

  return errors.map((error) => {
    const [, line, field] = error.field.split('.');
    const isFormatErrorByLine = checkFormatErrorByLine(error);
    if (!isFormatErrorByLine) return { ...error };

    const { code, metadata } = error;
    const params = metadata?.param || [];

    const param = params.reduce((acc, value, index) => {
      acc[`index.${index}`] = value;
      return acc;
    }, {});

    param.label = fields[field]?.label ?? '';
    const mergeMessages = {
      ...messages,
      ...(fields[field]?.message ?? {}),
    };

    return {
      ...error,
      line: `${line}行目`,
      message: formatMessage(mergeMessages[code] ?? '', { ...param }),
    };
  });
};
/**
 * Convert array number to string
 * @param {*} lines
 * @returns string
 */
export const getLinesString = (lines, size) => {
  const slicedArray = lines.slice(0, size);
  const str = slicedArray.join('行目、');
  if (lines.length > size) {
    return `${str}行目…`;
  }
  return `${str}行目`;
};

/**
 * Error is Group by Error CODE
 * @param {array} errors
 * @param {*} messages
 * @param {*} fields
 * @returns array
 */
export const formatErrorValidateMessage = (errors, messages, fields) => {
  if (_.isEmpty(errors)) return [];

  return errors.map((error) => {
    const { lines, field, code, metadata } = error;

    if (!lines) return { ...error };
    const LINE_SIZE = 10;
    const params = metadata?.param || [];
    const param = params.reduce((acc, value, index) => {
      if (_.isNaN(Number(value))) {
        acc[`index.${index}`] = value;
      } else {
        acc[`index.${index}`] = formatNumber(value);
      }
      acc.length = formatNumber(value);
      return acc;
    }, {});
    param.label = fields[field]?.label ?? '';
    const mergeMessages = {
      ...messages,
      ...(fields[field]?.message ?? {}),
    };

    return {
      ...error,
      line: getLinesString(lines, LINE_SIZE),
      message: formatMessage(mergeMessages[code] ?? '', { ...param }),
    };
  });
};

export const onOpenNewWindow = ({ url = '', type = '', optional = '' }) => {
  window.open(url, type, optional);
};

export const downloadTxtFile = (textValue, fileName) => {
  const file = new Blob([textValue], { type: 'text/plain' });
  const element = document.createElement('a');
  element.href = URL.createObjectURL(file);
  element.download = `${fileName}.txt`;
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};

export const getLinkRedirect = (field = '') => {
  switch (field) {
    case CONTENT_CATEGORY:
      return TAG_MANAGEMENT_CONTENT_CATEGORY;
    default:
      return false;
  }
};

/**
 * Sort array by key
 * @param {array} data
 * @param {string} key
 * @returns array
 */
export const sortByKey = (data, key, direction = SORT_DIRECTION_ASC) => {
  if (isEmpty(data)) return [];

  if (direction === SORT_DIRECTION_ASC) {
    return data.sort((a, b) => a[key] - b[key]);
  }
  return data.sort((a, b) => b[key] - a[key]);
};

/**
 * Handling the last dot in the string
 * @param {string} str
 * @param {bool} isAdditional // true => add, false => remove
 * @returns string
 */
export const dotLastString = (str, isAdditional = true) => {
  const dot = '。';
  const lastChar = str[str.length - 1];

  if (isAdditional && lastChar !== dot) {
    return `${str}${dot}`;
  }
  if (!isAdditional && lastChar === dot) {
    return str.slice(0, -1);
  }

  return str;
};

/**
 * @param {number} num
 * @param {func} callback
 * @returns array
 * Ex:  num = 5
 *      return [0, 1, 2, 3, 4]
 *
 *      num = 5, callback = (i) => i + 1
 *      return [1, 2, 3, 4, 5]
 */
export const convertNumberToArrayDigits = (num, callback = (i) => i) => {
  return new Array(Number(num)).fill('').map((__, i) => callback(i));
};

export const checkValueValid = (
  value,
  ignoreValues = [null, undefined, [], {}, 0, '', '0']
) => {
  return ignoreValues.every((item) => !_.isEqual(item, value));
};

/**
 * check url reachable
 * @param {string} url
 * @return bool
 */
export const checkUrlReachable = async (url) => {
  try {
    const res = await fetch(url, { method: 'GET', mode: 'no-cors' });
    const contentType = res.headers.get('content-type');
    if (contentType && contentType.indexOf('application/json') !== -1) {
      const data = await res.json();
      return !!data.success;
    }
    return true;
  } catch (err) {
    return false;
  }
};

/**
 * Convert protocal
 * @param {string} url
 * @param {bool} toHttps // true => convert to https, false => convert to http
 * @return string
 */
export const convertProtocal = (url, toHttps = true) => {
  if (toHttps) {
    return url.replace(/^http[s]?/, 'https');
  }
  return url.replace(/^http[s]?/, 'http');
};
