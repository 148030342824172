import React from 'react';
import { Link } from 'react-router-dom';
import * as fields from 'domain/fields';
import * as DisplayItems from 'domain/settings/display-items';
import {
  DETAILS_ANALYSIS,
  REFLECTING_TIME_GUIDE_LINK,
  LPO_LINK,
  LPO_PERIOD,
  LOG_ROUTE_ANALYZE,
  LOG_PAGE_ANALYZE,
  LOG_PERIOD_ANALYZE,
  TAG_MANAGEMENT_CONTENT_CATEGORY,
  APP_HELP_SC_SETTING,
  APP_HELP_LINE_ADD_FRIEND,
} from 'services/routes/constants';
import iconDataUpdateStatus from 'assets/images/data-update-status.svg';
import { COOKIE_PROGRAM_CHECK_TOOLTIP_MESSAGE } from 'domain/system-setting/consts';
import { FIELD_PREFIX } from 'domain/consts';
import { TAB_ALL } from 'services/consts';

const toolTipMessages = {
  [fields.CATEGORY]: '媒体種別/検索エンジン',
  [fields.CATEGORY_ID]: '媒体種別/検索エンジン',
  [fields.CATEGORY_NAME]: '媒体種別/検索エンジン',
  [fields.SYNC_CATEGORY]: '媒体/検索エンジン',
  [fields.SYNC_CATEGORY_ID]: '媒体/検索エンジン',
  [fields.SYNC_CATEGORY_NAME]: '媒体/検索エンジン',
  [fields.MEDIA_SIDE_AD_NAME]: '媒体広告名/検索ワード/リンク元ドメイン',
  [fields.CONTENT_CATEGORY]: (
    <>
      サイトコンテンツが「対象」で登録された場合、
      <Link to={`/${TAG_MANAGEMENT_CONTENT_CATEGORY}`}>
        [コンテンツカテゴリ]画面
      </Link>
      の設定にかかわらず、ページIDのコンテンツカテゴリが分析画面に反映されます。,
    </>
  ),
  [fields.AD_NAME]: '広告名/検索ワード/リンク元ドメイン',
  [fields.RCV]: (
    <>
      システム設定＞再配分CVモデル設定において
      <br />
      指定した比重でCVを割り振ったもの
    </>
  ),
  [fields.RCV_CROSS]: (
    <>
      システム設定＞再配分CVモデル設定において
      <br />
      指定した比重でCVを割り振ったもの
    </>
  ),
  [fields.CPA]: '広告コスト÷全体CV数',
  [fields.TCPA]: '広告コスト÷再配分CV',
  [fields.AMOUNT]: (
    <>
      対象施策接触後のCVで発生した
      <br />
      売上金額の合計
    </>
  ),
  [fields.RAMOUNT]: 'コンバージョン毎の再配分売上[売上金額×再配分CV]の合計',
  [fields.RAMOUNT_CROSS]:
    'コンバージョン毎の再配分売上[売上金額×再配分CV]の合計',
  [fields.CTR]: 'クリック数÷表示回数',
  [fields.ROAS]: '売上総額÷広告コスト×100',
  [fields.TROAS]: '再配分売上総額÷広告コスト×100',
  [fields.PER_RETURN]: '直帰数÷セッション数',
  [fields.CNT_RETURN]: (
    <>
      流入後、計測ページを1回読み込み、
      <br />
      サイトを離脱した回数
    </>
  ),
  [fields.PER_PAGE_VIEW]: '総PV数÷クリック数',
  [fields.CNT_SESSION]: 'ユーザーがサイトに流入してきた回数',
  [fields.TOTAL_STAY_TIME_AVG]: '総滞在時間÷（流入回数－直帰数）',
  [fields.TOTAL_STAY_TIME]: (
    <>
      計測対象サイトに流入してから
      <br />
      離脱までの滞在時間の合計
    </>
  ),
  [fields.AD_COST_RATIO]: '(広告コスト÷広告コスト合計)×100',
  [fields.REALLOCATION_CV_RATIO]: '(再配分CV÷再配分CV合計)×100',
  [fields.REALLOCATION_SALES_RATIO]: '(再配分売上÷再配分売上合計)×100',
  [fields.RCV_COST_EFFECTIVENESS]: '(再配分CV割合÷コスト割合)×100',
  [fields.RAMOUNT_COST_EFFECTIVENESS]: '(再配分売上割合÷コスト割合)×100',
  [fields.LANDING_PAGE_DOMAIN]: (
    <>
      チャネル種別が
      <br />
      広告クリック、自然検索、
      <br />
      外部リンク、ダイレクトの場合は、
      <br />
      流入後に着地したページの
      <br />
      ページドメインを表示します。また、広告クリックは、ダイレクト計測の場合のみ表示します。
    </>
  ),
  [fields.LANDING_PAGE_URL]:
    '流入先としてユーザーに表示されたWebページのURLです。サイトコンテンツ対象（コンテンツカテゴリ）設定しているページの場合、そのページのURLが流入先として計測されます。',
  [fields.RANK]:
    'ランディングページが複数のコンテンツカテゴリにマッチする場合、優先順位のより高いものが適用されます。',
  [fields.PAGE_IDS]:
    'ページID単位でサイトコンテンツ「対象」と登録されている場合、URL条件の優先順位にかかわらずページIDに紐づくコンテンツカテゴリが分析画面に反映されます。',
  [fields.OWNED_MEDIA_FLAG]: (
    <>
      サイトコンテンツが「対象外」で登録されていても、
      <Link to={`/${TAG_MANAGEMENT_CONTENT_CATEGORY}`}>
        [コンテンツカテゴリ]画面
      </Link>
      のURL指定条件の設定次第ではサイトコンテンツ「対象」として分析画面に反映されます。
    </>
  ),
  [fields.CONTENT_CATEGORY_NAME]: (
    <>
      サイトコンテンツが「対象」で登録された場合、
      <Link to={`/${TAG_MANAGEMENT_CONTENT_CATEGORY}`}>
        [コンテンツカテゴリ]画面
      </Link>
      の設定にかかわらず、ページIDのコンテンツカテゴリが分析画面に反映されます。
    </>
  ),
  [fields.CNT_INDIRECT_OTHER]: '間接効果11以前の初回接触も含みます。',
  [fields.ADVERTISER_ID]: '接続中の媒体の広告アカウントIDです。',
  [fields.MEDIA_DISPLAY_NAME]: '分析画面の[媒体]に表示される名称です。',
  [fields.FIRST_PARTY_COOKIE_PROGRAM_URL_CHECK]: COOKIE_PROGRAM_CHECK_TOOLTIP_MESSAGE,
  [fields.CNAME_DOMAIN_FIELD]:
    '[*]がついているドメインはASPサービスのドメインです',
  [DisplayItems.PER_PV]: 'PV数÷合計PV数',
  [DisplayItems.PER_SESSION]: 'SU数÷合計SU数',
  [DisplayItems.PER_UU]: 'UU数÷合計UU数',
  [DisplayItems.CNT_ENTRANCE]: 'セッションの中で最初に見られた数。',
  [DisplayItems.CNT_EXIT]: 'セッションの中で最後に見られた数。',
  [FIELD_PREFIX.CNT_CV_CONTRIBUTE]: '同一セッション内でPV後に発生したCV数',
  [DisplayItems.CV_CONTRIBUTE_TOTAL]: '同一セッション内でPV後に発生したCV数',
  [FIELD_PREFIX.CNT_CVR_CONTRIBUTE]: 'CV貢献度÷セッション数',
  [DisplayItems.CVR_CONTRIBUTE_TOTAL]: 'CV貢献度÷セッション数',
  [fields.ADD_FRIEND_BUTTON_URL]: (
    <>
      ウェブサイト上にある友だち追加ボタン部分のソースコードに記述してください。詳しくは
      <a
        href={APP_HELP_LINE_ADD_FRIEND}
        target="_blank"
        rel="noopener noreferrer"
      >
        こちら
      </a>
    </>
  ),
};

const tooltipOverrideMessages = {
  [DETAILS_ANALYSIS]: {
    [fields.LANDING_PAGE_URL]:
      '流入先としてユーザーに表示されたWebページのURLです。',
  },
  [LPO_LINK]: {
    [fields.LINK_URL]: '広告管理で設定された広告リンク先URL',
    [fields.LINK_PAGE_TITLE]: '広告管理で設定された広告リンク先タイトル',
    [fields.COST]:
      '広告コストをリンク先ページごとのクリック数の比率で割り振ったもの',
  },
  [LPO_PERIOD]: {
    [fields.COST]:
      '広告コストをリンク先ページごとのクリック数の比率で割り振ったもの',
  },
  [LOG_PAGE_ANALYZE]: {
    [DisplayItems.TOTAL_STAY_TIME_AVG]:
      'ページIDが集計軸に選択されている場合は「合計滞在時間÷(PV-離脱数)」、それ以外の場合は「合計滞在時間÷（SU-直帰数)」',
    [DisplayItems.CONTENT_CATEGORY]: (
      <>
        流入したWebページに設定された
        <Link to={`/${TAG_MANAGEMENT_CONTENT_CATEGORY}`}>
          コンテンツカテゴリ
        </Link>
      </>
    ),
    [DisplayItems.PER_RETURN]: '直帰数÷流入数',
    [DisplayItems.CNT_UU]: (
      <>
        アクセスしたユーザー数。UUの算出方法については
        <a
          href="https://support.ebis.ne.jp/s/article/000004821#uu_specification"
          target="_blank"
          rel="noopener noreferrer"
        >
          こちら
        </a>
      </>
    ),
  },
  [LOG_PERIOD_ANALYZE]: {
    [DisplayItems.CNT_UU]: (
      <>
        アクセスしたユーザー数。UUの算出方法については
        <a
          href="https://support.ebis.ne.jp/s/article/000004822#uu_specification"
          target="_blank"
          rel="noopener noreferrer"
        >
          こちら
        </a>
      </>
    ),
  },
  [LOG_ROUTE_ANALYZE]: {
    [DisplayItems.RANK]: '経路ごとに連番で付与された番号',
    [DisplayItems.RATE]: '該当の経路でサイト回遊したSU÷条件に合致する全てのSU',
    [DisplayItems.CNT]: '該当の経路でサイト回遊したSU',
  },
};

const tooltipOverrideByTabMessages = {
  [DETAILS_ANALYSIS]: {
    [TAB_ALL]: {
      [fields.TERMINAL_TYPE]: (
        <>
          Google Search
          Console連携のデータを反映している場合、「名称」に表示される検索ワードはデバイス別の成果を加味したデータではありません。Google
          Search Console連携の連携仕様については
          <a
            href={APP_HELP_SC_SETTING}
            target="_blank"
            rel="noopener noreferrer"
          >
            こちら
          </a>
        </>
      ),
    },
  },
};

const displayItemTooltipMessage = '集計軸で[広告ID]を選択してください';

const crossDeviceWarningMessages = {
  'PATTERN-A': (
    <>この画面はクロスデバイスに対応していないため、無効になっています。</>
  ),
  'PATTERN-B': (
    <>
      クロスデバイス分析が完了していない期間が含まれているため、無効になっています。
      <br />
      ※クロスデバイス分析状況については画面右上の &nbsp;
      <img
        src={iconDataUpdateStatus}
        alt="icon-caution"
        width="14px"
        height="14px"
      />
      （反映時間）をクリックしてご確認ください
    </>
  ),
  'PATTERN-B-DATA-EXPORT': (
    <>
      クロスデバイス分析が完了していない期間が含まれています。集計期間を変更するか、フィルタからクロスデバイスを除去してください。
      <br />
      ※クロスデバイス分析状況については画面右上の
      <img
        src={iconDataUpdateStatus}
        alt="icon-caution"
        width="14px"
        height="14px"
      />
      （反映時間）をクリックしてご確認ください。
    </>
  ),
  'PATTERN-C': (
    <>
      この画面は「集計チャネル：クリック＋自然検索＋ダイレクト＋外部リンク＋サイト内」に対応していないため、全チャネルを表示しています。
    </>
  ),
  'PATTERN-D': (
    <>
      クロスデバイスは「集計チャネル：クリック＋自然検索＋ダイレクト＋外部リンク＋サイト内」に対応していないため、無効になっています。
    </>
  ),
};

const crossDeviceDateMessage = (
  <>
    分析・更新タイミングの詳細については
    <a
      href={REFLECTING_TIME_GUIDE_LINK}
      target="_blank"
      rel="noopener noreferrer"
    >
      こちら
    </a>
  </>
);

const toolTipReflectionStatusMainMessage = <>40～70分間隔で更新されます</>;

const tooltipGoogleSearchConsoleMessage = (
  <>
    データの連携タイミングについては
    <a
      href={REFLECTING_TIME_GUIDE_LINK}
      target="_blank"
      rel="noopener noreferrer"
    >
      こちら
    </a>
  </>
);

// Defines the "field name" if you want the Tool Tip itself to remain visible when hovering over it.
const stayOpenFields = [
  fields.IS_IMPORTING_COSTS,
  fields.FIRST_PARTY_COOKIE_PROGRAM_URL_CHECK,
  DisplayItems.CNT_UU,
  DisplayItems.CONTENT_CATEGORY,
  fields.TERMINAL_TYPE,
  fields.ADD_FRIEND_BUTTON_URL,
  fields.OWNED_MEDIA_FLAG,
  fields.CONTENT_CATEGORY_NAME,
];

const lpoCancelledTooltipMassage = 'LPO機能は解約済のためご利用いただけません';

export default {
  tooltipOverrideMessages,
  tooltipOverrideByTabMessages,
  toolTipMessages,
  toolTipReflectionStatusMainMessage,
  tooltipGoogleSearchConsoleMessage,
  stayOpenFields,
  crossDeviceWarningMessages,
  crossDeviceDateMessage,
  displayItemTooltipMessage,
  lpoCancelledTooltipMassage,
};
