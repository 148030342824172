/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import axios from 'axios';
import { loadAuth, saveAuth } from 'services/LocalStorageService';
import logging from 'domain/logging';
import {
  CATEGORY_ANALYZE,
  DETAILS_ANALYSIS,
  COMPARE_PERIOD,
  LANDING_PAGE_ANALYZE,
  CV_ATTRIBUTE,
  CV_FLOW,
  COST_ALLOCATION,
  LPO_LINK,
  LPO_PERIOD,
  LOG_PAGE_ANALYZE,
  LOG_PERIOD_ANALYZE,
  LOG_ROUTE_ANALYZE,
} from 'services/routes/constants';
import pages from 'services/routes/pages';
import timeoutNotify from 'domain/timeout-notify';
import logout from 'domain/logout';
import types from 'store/auth/types';
import { REGEX_TOKEN } from 'services/regexPatterns';

const REFRESH_TOKEN_URL = `${process.env.REACT_APP_ID_EBIS_API_HOST}/token`;

const download = (url, filename, keepEle = false) => {
  // Create a new anchor element
  const a = document.createElement('a');

  // Set the href and download attributes for the anchor element
  // You can optionally set other attributes like `title`, etc
  // Especially, if the anchor element will be attached to the DOM
  a.href = url;
  a.download = filename || 'download';

  // Programmatically trigger a click on the anchor element
  // Useful if you want the download to happen automatically
  // Without attaching the anchor element to the DOM
  // Comment out this line if you don't want an automatic download of the blob content
  a.click();

  if (keepEle === false) {
    a.remove();

    return null;
  }
  // Return the anchor element
  // Useful if you want a reference to the element
  // in order to attach it to the DOM or use it in some other way
  return a;
};

const asyncEndpoints = [
  CATEGORY_ANALYZE,
  DETAILS_ANALYSIS,
  COMPARE_PERIOD,
  LANDING_PAGE_ANALYZE,
  CV_ATTRIBUTE,
  CV_FLOW,
  COST_ALLOCATION,
  LPO_LINK,
  LPO_PERIOD,
  LOG_PAGE_ANALYZE,
  LOG_PERIOD_ANALYZE,
  LOG_ROUTE_ANALYZE,
].map((pageId) => pages[pageId].endpoint);

// Screen Timeout
const screenTimeouts = [
  CATEGORY_ANALYZE,
  DETAILS_ANALYSIS,
  COMPARE_PERIOD,
  LANDING_PAGE_ANALYZE,
  CV_ATTRIBUTE,
  CV_FLOW,
  COST_ALLOCATION,
  LPO_LINK,
].map((pageId) => pages[pageId].endpoint);

const isScreenTimeout = (url) => {
  const pattern = new RegExp(`(${url})([?].*)?$`);
  return screenTimeouts.some((path) => pattern.test(path));
};

const expiredTokenRequestQueue = [];
let isRefreshing = false;

const getAccessToken = () => {
  const token = loadAuth();
  if (token) {
    return token.accessToken;
  }
  return null;
};

const updateUserInfo = (accessToken) => {
  import('store/store').then((storeModule) => {
    const { store } = storeModule;
    // The current logic specifies that store is only configured once the first request to get user detail is completed
    // In case the first request failed, we do not need to do anything here, since it is the request we are going to retry below
    // We should only get user detail once this is not the first request
    if (store) {
      import('domain/auth').then((authModule) =>
        authModule.default.fetchUser(accessToken).then((user) => {
          if (user) {
            store.dispatch({ type: types.UPDATE_USER, payload: user });
          }
        })
      );
    }
  });
};

const downloadQueues = {};

const ApiFactory = (baseURL = process.env.REACT_APP_CONTENTS_API_HOST) => {
  const { CancelToken } = axios;
  const source = CancelToken.source();

  const instance = axios.create({
    baseURL,
    timeout: 29000,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    cancelToken: source.token,
  });

  instance.setToken = (token) => {
    instance.defaults.headers.Authorization = `Bearer ${token}`;
    return token;
  };

  const isAsyncEndpoint = (url) => {
    const pattern = new RegExp(`(${url})([?].*)?$`);
    return asyncEndpoints.some((path) => pattern.test(path));
  };

  const handleSuccess = (response) => {
    const originalRequest = response.config;

    // handle for case sync request return file: process as async
    const { location = '' } = response?.data?.data || {};
    if (
      isAsyncEndpoint(originalRequest.url) &&
      response.status === 200 &&
      location &&
      location.indexOf('.json') > 0
    ) {
      originalRequest.batchRequest = true;
    }

    // batch request
    if (originalRequest.batchRequest) {
      if (response.status === 202) {
        // Change to poll request
        let pollUrl = originalRequest?.pollUrl ?? '/poll';
        let token;
        if (originalRequest.pollToken) {
          token = originalRequest.pollToken;
        } else {
          token = response.data.data.token;
          originalRequest.pollToken = token;
        }
        // Add queues download inprogress
        if (!(token in downloadQueues)) {
          downloadQueues[token] = response.config.url;
        }

        let params = { token };
        if (originalRequest?.tokenInPath) {
          pollUrl += `/${token}`;
          params = {};
        }

        return instance.get(pollUrl, {
          ...originalRequest,
          params,
        });
      }
      if (response.status === 200) {
        // poll request success
        // load data from s3
        if (location.indexOf('.csv') >= 0) {
          download(location);
          if (originalRequest.pollToken in downloadQueues) {
            response.data.urlDownload =
              downloadQueues[originalRequest.pollToken];
            delete downloadQueues[originalRequest.pollToken];
          }
          return response;
        }
        return instance.get(location);
      }
    }

    return response;
  };

  const refreshToken = (error) => {
    const token = loadAuth();
    if (token) {
      return axios.post(REFRESH_TOKEN_URL, {
        grant_type: 'refresh_token',
        refresh_token: token.refreshToken,
      });
    }
    return Promise.reject(error);
  };

  const requestAsync = async (
    url,
    data,
    method = 'POST',
    config = {},
    callback = () => {}
  ) => {
    const {
      pollUrlFormat,
      pollTokenKey,
      maxRetry = process.env.REACT_APP_ASYNC_RETRY_MAX || 10,
      retryInterval = process.env.REACT_APP_ASYNC_INTERVAL || 5000,
      retryIntervalLow = process.env.REACT_APP_ASYNC_INTERVAL_LOW || 1000,
      retryIntervalLowMax = 0,
    } = config;
    const initialResponse = await instance.request({ method, url, data });
    await callback();
    if (initialResponse.status === 202) {
      const pollToken =
        initialResponse.data[pollTokenKey] ||
        initialResponse.data.data[pollTokenKey];
      let retryCount = 0;
      let success = false;
      let response;
      const pollUrl = pollUrlFormat.replaceAll(`{${pollTokenKey}}`, pollToken);
      while (retryCount < maxRetry && !success) {
        // TODO rewrite with function style
        // eslint-disable-next-line no-await-in-loop
        const pollResponse = await instance.get(pollUrl);

        if (pollResponse.status === 202) {
          let retryIntervalFlex;
          if (retryCount >= retryIntervalLowMax) {
            retryIntervalFlex = retryInterval;
          } else {
            retryIntervalFlex = retryIntervalLow;
          }

          // eslint-disable-next-line no-await-in-loop
          await (() =>
            new Promise((resolve) => setTimeout(resolve, retryIntervalFlex)))();
          retryCount += 1;
        } else {
          response = pollResponse;
          success = true;
          break;
        }
      }
      if (!success) {
        throw axios.Cancel('Max retry reached');
      }
      return response;
    }
    return initialResponse;
  };

  const handleError = (error) => {
    const { config: originalRequest } = error;
    // handle async flow
    if (
      (error.code === 'ECONNABORTED' || error?.response?.status === 408) &&
      !REGEX_TOKEN.test(originalRequest.url)
    ) {
      if (
        isAsyncEndpoint(originalRequest.url) &&
        !originalRequest.batchRequest
      ) {
        // Request Timeout
        originalRequest.batchRequest = true;

        // Change to async
        const asyncUrl = `${originalRequest.url}/exports/json`;

        try {
          const data = JSON.parse(originalRequest.data);
          return instance.request({
            ...originalRequest,
            timeout: 0,
            method: 'post',
            url: asyncUrl,
            data,
          });
        } catch (err) {
          logging.info(
            'Skip make batch request, because parse json original request data fail',
            originalRequest
          );
        }
      } else if (originalRequest.isAsyncToSync) {
        const dataRequest = JSON.parse(originalRequest.data);
        return requestAsync(
          originalRequest.asyncUrl,
          dataRequest,
          originalRequest.method,
          originalRequest.config
        );
      } else {
        // advice user retry
        timeoutNotify.push(error);
        logging.warn(`A timeout happend on url ${error.config.url}`);
      }
    }

    // handle where access token expired
    if (error?.response?.status === 401 && !originalRequest._retry) {
      if (!isRefreshing) {
        isRefreshing = true;
        return refreshToken(error)
          .then((res) => {
            originalRequest._retry = true;

            const {
              access_token: newAccessToken,
              refresh_token: newRefreshToken,
            } = res.data;

            saveAuth({
              accessToken: newAccessToken,
              refreshToken: newRefreshToken,
            });
            // Set the new access token to current instance
            instance.setToken(newAccessToken);

            updateUserInfo(newAccessToken);

            // Set new access token to failed request
            originalRequest.headers.Authorization = `Bearer ${newAccessToken}`;
            expiredTokenRequestQueue.forEach((cb) => cb(newAccessToken));
            // Clear the queue
            expiredTokenRequestQueue.length = 0;
            // set refresh status to false when done
            isRefreshing = false;
            return instance(originalRequest);
          })
          .catch((err) => {
            logging.warn('refresh token error', err);
            // In case where refresing token failed
            if (err?.response?.config?.url === REFRESH_TOKEN_URL) {
              // redirect to login site
              return logout({
                logoutSite: false,
              });
            }
            return Promise.reject(err);
          });
      }
      // if there's existing refreshing reqs, put other failed reqs into queue
      return new Promise((resolve) => {
        expiredTokenRequestQueue.push((token) => {
          originalRequest.headers.Authorization = `Bearer ${token}`;
          resolve(instance(originalRequest));
        });
      });
    }

    if (
      'pollToken' in error.config &&
      error.config.pollToken in downloadQueues
    ) {
      error.urlDownload = downloadQueues[error.config.pollToken];
      delete downloadQueues[originalRequest.pollToken];
    }

    return Promise.reject(error);
  };

  instance.interceptors.response.use(handleSuccess, handleError);

  instance.interceptors.request.use(async (config) => {
    // limit timeout for async endpoints
    const pattern = new RegExp(`(${config.url})([?].*)?$`);
    if (asyncEndpoints.some((path) => pattern.test(path))) {
      config.timeout = process.env.REACT_APP_REQUEST_TIMEOUT || 15000;
      // Increase timeout value to 120s for screen timeout
      if (isScreenTimeout(config.url)) {
        config.timeout = process.env.REACT_APP_SCREEN_TIMEOUT || 120000;
      }
      logging.log('config.timeout', config.timeout);
    }
    // Set access token to request
    const token = getAccessToken();
    // if url get token, don't assign token to headers
    if (token && !REGEX_TOKEN.test(config.url)) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    // Interval between two async request check result status
    if (config.batchRequest) {
      const retry = config.asyncRetry || 0;
      const maxRetry = process.env.REACT_APP_ASYNC_RETRY_MAX || 10;
      if (retry > maxRetry) {
        throw new axios.Cancel('Over max retry!');
      }
      if (retry > 0) {
        await (() =>
          new Promise((resolve) =>
            setTimeout(resolve, process.env.REACT_APP_ASYNC_INTERVAL || 5000)
          ))();
      }
      config.asyncRetry = retry + 1;
    }

    return config;
  });

  // request sync, if timeout => request async
  const requestSyncToAsync = async ({
    syncUrl,
    asyncUrl,
    data,
    method = 'POST',
    config = {},
  }) => {
    const initialResponse = await instance.request({
      method,
      url: syncUrl,
      data,
      config,
      asyncUrl,
      isAsyncToSync: true,
    });
    return initialResponse;
  };

  return {
    get: async (url, config) => instance.get(url, config),
    post: async (url, data, config) => instance.post(url, data, config),
    put: async (url, data, config) => instance.put(url, data, config),
    delete: async (url, config) => instance.delete(url, config, config),
    update: async (url, config) => instance.post(url, config, config),
    patch: async (url, data, config) => instance.patch(url, data, config),
    cancel: () => source.cancel('Operation canceled by the user.'),
    // Deprecated
    getRequest: async (url) => instance.get(url),
    // Deprecated
    postRequest: async (url, data) => instance.post(url, data),
    // Deprecated
    putRequest: async (url, data) => instance.put(url, data),
    // Deprecated
    deleteRequest: async (url) => instance.delete(url),
    downloadCsv: async (url, data, config) =>
      instance.post(`${url}/exports/csv`, data, {
        ...config,
        batchRequest: true,
        timeout: 0,
      }),
    requestAsync: async (url, data, method, config, callback) =>
      requestAsync(url, data, method, config, callback),
    requestSyncToAsync: async ({
      syncUrl,
      asyncUrl,
      data,
      method,
      config,
      callback,
    }) =>
      requestSyncToAsync({ syncUrl, asyncUrl, data, method, config, callback }),
    refreshToken: async () => {
      try {
        const token = loadAuth();
        const res = await instance.post(REFRESH_TOKEN_URL, {
          grant_type: 'refresh_token',
          refresh_token: token.refreshToken,
        });

        saveAuth({
          accessToken: res?.data?.access_token,
          refreshToken: res?.data?.refresh_token,
        });
      } catch (error) {
        logout({ logoutSite: false });
      }
    },
    updateUserInfo,
  };
};

const Api = ApiFactory();
export const SettingsApi = ApiFactory(process.env.REACT_APP_SETTINGS_API_HOST);
export const LtvApi = ApiFactory(process.env.REACT_APP_LTV_API_HOST);
export const AuthApi = ApiFactory(process.env.REACT_APP_AUTH_API_HOST);
export const FbCApi = ApiFactory(process.env.REACT_APP_FB_CAPI_HOST);
export default Api;
