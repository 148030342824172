/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Alert, Container } from 'react-bootstrap';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import iconCautionYellow from 'assets/images/icon-caution-yellow.svg';
import 'views/molecules/alertbar/alertbar.scss';
import alertActions from 'store/alerts/actions';
import { alertsSelectors } from 'store/alerts';
import {
  OLD_EBIS_HOME_SITE,
  EXTERNAL,
  CAPI_SETTING,
  MEDIA_SYNC_MANAGEMENT,
  SEARCH_CONSOLE_SETTING,
  SYSTEM_SETTING_NS,
  SYSTEM_SETTING_CNAME,
} from 'services/routes/constants';
import ExternalTransitionWrapper from 'services/routes/ExternalTransitionWrapper';

const WARNING_CAPI = 'warning_capi';
const WARNING_SEARCH_CONSOLE = 'warning_search_console';
const WARNING_MEDIA_SYNC = 'warning_media_sync';
const ALERT_NS_UNHEALTHY = 'alert_ns_unhealthy';
const ALERT_CNAME_UNHEALTHY = 'alert_cname_unhealthy';

const LIST_REDIRECT_INSIDE = {
  [WARNING_CAPI]: { url: `/${CAPI_SETTING}`, textUrlDisplay: '設定画面' },
  [WARNING_SEARCH_CONSOLE]: {
    url: `/${SEARCH_CONSOLE_SETTING}`,
    textUrlDisplay: '設定画面でご確認ください。',
  },
  [WARNING_MEDIA_SYNC]: {
    url: `/${MEDIA_SYNC_MANAGEMENT}`,
    textUrlDisplay: '設定画面',
  },
  [ALERT_NS_UNHEALTHY]: {
    url: `/${SYSTEM_SETTING_NS}`,
    textUrlDisplay: 'こちら',
  },
  [ALERT_CNAME_UNHEALTHY]: {
    url: `/${SYSTEM_SETTING_CNAME}`,
    textUrlDisplay: 'こちら',
  },
};

const TEXT_URL_SPLIR = '%URL%';

// eslint-disable-next-line react/prop-types
const ReplaceTextWithLinkComponent = ({ service, str, url }) => {
  const splittedStr = str.split(/(%URL%)/);
  const isRedirectInside = Object.keys(LIST_REDIRECT_INSIDE).includes(service);
  // handle replace link redirect inside
  if (isRedirectInside) {
    splittedStr[splittedStr.indexOf(TEXT_URL_SPLIR)] = (
      <Link to={LIST_REDIRECT_INSIDE[service].url}>
        {LIST_REDIRECT_INSIDE[service].textUrlDisplay}
      </Link>
    );
    return splittedStr.map((item) => <>{item}</>);
  }
  // handle redirect outside or support side
  if (url) {
    const { origin } = new URL(url);

    // eslint-disable-next-line prefer-destructuring
    splittedStr[splittedStr.indexOf(TEXT_URL_SPLIR)] =
      origin === OLD_EBIS_HOME_SITE ? (
        <ExternalTransitionWrapper link={url} type={EXTERNAL} newTab={false}>
          こちら
        </ExternalTransitionWrapper>
      ) : (
        <a href={url} target="_blank" rel="noopener noreferrer">
          こちら
        </a>
      );
  }

  return splittedStr.map((item, index) => (
    // eslint-disable-next-line react/no-array-index-key
    <React.Fragment key={index}>{item}</React.Fragment>
  ));
};

const WarningList = ({ data }) => {
  const [page, setPage] = useState(1);
  const handleWarningIndex = (currentPage, step, lastPage) => {
    if (currentPage + step < 1 || currentPage + step > lastPage) {
      setPage(currentPage);
    } else {
      setPage(currentPage + step);
    }
  };

  return (
    <Alert className="alert-warning">
      <a
        className={page === 1 ? 'page disabled' : 'page'}
        onClick={() => handleWarningIndex(page, -1, data.length)}
      >
        {' '}
        <i className="fas fa-chevron-left" />{' '}
      </a>
      <span>
        {page}/{data.length}
      </span>
      <a
        className={page === data.length ? 'page disabled' : 'page'}
        onClick={() => handleWarningIndex(page, 1, data.length)}
      >
        {' '}
        <i className="fas fa-chevron-right" />{' '}
      </a>
      <img src={iconCautionYellow} alt="icon-caution" />
      <ReplaceTextWithLinkComponent
        service={data[page - 1].service}
        str={data[page - 1].notice}
        url={data[page - 1].url}
      />
    </Alert>
  );
};

WarningList.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      notice: PropTypes.string.isRequired,
      url: PropTypes.string,
      service: PropTypes.string.isRequired,
    })
  ).isRequired,
};

const AlertList = React.memo(({ data }) => (
  <div className="alerts">
    {data.map((item) => (
      <Alert key={item.service} variant="primary">
        <i className="fas fa-exclamation-circle text-danger" />
        <ReplaceTextWithLinkComponent
          str={item.notice}
          url={item.url}
          service={item.service}
        />
      </Alert>
    ))}
  </div>
));

AlertList.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      notice: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })
  ).isRequired,
};

const AlertBar = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    // TODO: put this in a common service (start up services)
    dispatch(alertActions.fetchAlertsStart());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const apiData = useSelector((state) =>
    alertsSelectors.getAlertsData(state, shallowEqual)
  );

  const alertsData = (apiData && apiData.alert) || false;
  const warningsData = (apiData && apiData.warning) || false;

  return (
    <>
      {(alertsData.length > 0 || warningsData.length > 0) && (
        <Container fluid className="alert-warning-wrapper">
          {alertsData.length > 0 && <AlertList data={alertsData} />}
          {warningsData.length > 0 && <WarningList data={warningsData} />}
        </Container>
      )}
    </>
  );
};

export default React.memo(AlertBar);
