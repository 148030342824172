import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { bool, func, shape, string } from 'prop-types';
import { Row, Col } from 'react-bootstrap';
import ScrollbarWrapper from 'views/atoms/scrollbar/ScrollbarWrapper';
import './filterSelection.scss';
import {
  PRIORITY_AXIS_BOTH,
  PRIORITY_AXIS_EBIS,
  PRIORITY_AXIS_MEDIA_SYNC,
} from 'domain/consts';
import {
  ONLY_EBIS_AXIS_FILTERS,
  ONLY_MEDIA_SYNC_AXIS_FILTERS,
  FILTER_KEY_CHANNEL,
  FILTER_KEY_CROSS_DEVICE,
} from 'services/consts';
import MediaSyncIcon from 'views/atoms/MediaSyncIcon/MediaSyncIcon';
import { settingsSelectors } from 'store/settings';
import { settingsForViewSelectors } from 'store/settings-for-view';
import { HiddenFilterConfig } from 'domain/commonbar/FilterConfig';
import { isWarningFilter } from 'services/utils';
import { LOG_PAGE_ANALYZE } from 'services/routes/constants';

const isHiddenFilter = (filterKey, screenId) => {
  switch (filterKey) {
    case FILTER_KEY_CHANNEL:
      return false;
    case FILTER_KEY_CROSS_DEVICE:
      return !HiddenFilterConfig[filterKey].includes(screenId);
    default:
      return false;
  }
};

function FilterSelectionItem(props) {
  const {
    item,
    filterKey,
    onClick,
    sync,
    disabled,
    customView,
    listFilterHidden,
  } = props;
  const screenIdForMain = useSelector(settingsSelectors.getPage);
  const screenIdForView = useSelector(settingsForViewSelectors.getPage);
  const screenId = customView ? screenIdForView : screenIdForMain;

  // only DataExport
  if (listFilterHidden.includes(filterKey)) {
    return <></>;
  }

  // Disable filter list in the filter disable control
  if (isHiddenFilter(filterKey, screenId)) {
    return <></>;
  }

  return (
    <Row style={{ order: item.order }}>
      <Col>
        {item.isTitleGroup ? (
          <div className="filter-selection-group">{item.title}</div>
        ) : (
          <button
            type="button"
            className="filter-selection-item"
            disabled={disabled}
            onClick={onClick}
          >
            <div>{item.title}</div>
            {sync ? (
              <MediaSyncIcon className="filter-selection-item__sync-icon" />
            ) : null}
          </button>
        )}
      </Col>
    </Row>
  );
}

FilterSelectionItem.propTypes = {
  item: shape({ title: string }).isRequired,
  filterKey: string.isRequired,
  sync: bool.isRequired,
  onClick: func,
  disabled: bool.isRequired,
  customView: bool,
  listFilterHidden: shape([]),
};

FilterSelectionItem.defaultProps = {
  onClick: () => {},
  customView: false,
  listFilterHidden: [],
};

const FilterSelectionMenu = React.forwardRef(
  (
    {
      items,
      onSelect,
      setShowSelectionMenu,
      priorityAxis,
      pageId,
      displayItems,
      period,
      customView,
      listFilterHidden,
    },
    ref
  ) => {
    const handleClick = (key, { order, type, title }) => {
      onSelect(key, { order, type, title });
    };

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (ref && !ref.current.contains(event.target)) {
          setShowSelectionMenu(false);
        }
      };
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getFilterWarning = (key) => {
      if ([LOG_PAGE_ANALYZE].includes(pageId)) {
        const { isWarning } = isWarningFilter(
          key,
          items,
          pageId,
          period,
          [],
          displayItems
        );
        return isWarning;
      }
      return false;
    };

    return (
      <ScrollbarWrapper
        ref={ref}
        maxContent={250}
        sizeScroll={5}
        alignScroll={5}
      >
        {Object.keys(items)
          .filter(
            (key) =>
              priorityAxis === PRIORITY_AXIS_BOTH ||
              (priorityAxis === PRIORITY_AXIS_MEDIA_SYNC &&
                !ONLY_EBIS_AXIS_FILTERS.includes(key)) ||
              (priorityAxis === PRIORITY_AXIS_EBIS &&
                !ONLY_MEDIA_SYNC_AXIS_FILTERS.includes(key))
          )
          .map((key) => {
            return (
              <FilterSelectionItem
                filterKey={key}
                item={items[key]}
                onClick={() => handleClick(key, items[key])}
                sync={ONLY_MEDIA_SYNC_AXIS_FILTERS.includes(key)}
                disabled={getFilterWarning(key)}
                customView={customView}
                key={key}
                listFilterHidden={listFilterHidden}
              />
            );
          })}
      </ScrollbarWrapper>
    );
  }
);

FilterSelectionMenu.propTypes = {
  items: shape({}).isRequired,
  onSelect: func,
  setShowSelectionMenu: func,
  priorityAxis: string.isRequired,
  pageId: string.isRequired,
  displayItems: shape({}).isRequired,
  period: shape({}).isRequired,
  customView: bool,
  listFilterHidden: shape([]),
};

FilterSelectionMenu.defaultProps = {
  listFilterHidden: [],
  onSelect: () => {},
  setShowSelectionMenu: () => {},
  customView: false,
};

export default FilterSelectionMenu;
